<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn" @click="$emit('update:modelValue', false);"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <Form class="workout-wizard-form" v-slot="{ errors }" ref="workout-wizard-form">
                    <div class="step_wpr content_area">
                        <div class="info_bar">
                            <div class="time">Estimated Time: <span>6 min.</span></div>
                            <div class="step_bar2">
                                <ul>
                                    <li @click="toggleStep(1);" :class="{ 'active' : workoutStep == 1 || workoutStep > 1}">
                                        <span>1</span>
                                        <h6>Interval</h6>
                                    </li>
                                    <li @click="toggleStep(2)" :class="{ 'active' : workoutStep == 2 || workoutStep > 2}">
                                        <span>2</span>
                                        <h6>Workout</h6>
                                    </li>
                                    <li @click="toggleStep(3)" :class="{ 'active' : workoutStep == 3 || workoutStep > 3}">
                                        <span>3</span>
                                        <h6>Public Page</h6>
                                    </li>
                                    <li @click="toggleStep(4);" :class="{ 'active' : workoutStep == 4 || workoutStep > 4}">
                                        <span>4</span>
                                        <h6>Notifications</h6>
                                    </li>
                                    <li @click="toggleStep(5)" :class="{ 'active' : workoutStep == 5 || workoutStep > 5 }">
                                        <span>5</span>
                                        <h6>SEO</h6>
                                    </li>
                                </ul>
                                <div class="total_bar"><span :style="`width:${(100 / 4) * (workoutStep - 1) }%`"></span></div>
                            </div>
                        </div>
                        <ul class="btn_list" v-if="workoutStep == 3">
                            <li v-if="toggleAll" @click="openAllSection">Open All</li>
                            <li v-else @click="closeAllSection">Close All</li>
                        </ul>
                        <div class="content_wpr" v-if="workoutStep == 1">
                            <div class="section_content w-100 open_area">
                                <div class="section_header">
                                    <h2>Workout Name</h2>
                                </div>
                                <div class="color_container">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Workout Name</label>
                                                <div class="field_wpr">
                                                    <input type="text" placeholder="ex: Progress Tracking Name">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                    
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <label for="one-time" class="switch_option capsule_btn">
                                        <h2>One-time Workout</h2>
                                        <input type="radio" id="one-time" value="one-time" v-model="form.public_settings.workout_type" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="section_header">
                                    <label for="recurring" class="switch_option capsule_btn">
                                        <h2>Recurring Workout</h2>
                                        <input type="radio" id="recurring" value="recurring" v-model="form.public_settings.workout_type" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <Transition duration="550" name="nested">
                                    <div class="color_container" v-if="form.public_settings.workout_type == 'recurring'">
                                        <h4 class="sec_header">Recurring Interval</h4>
                                        <div class="setting_wpr">
                                            <ul class="time_listing">
                                                <li>
                                                    <label for="daily" class="checkbox">
                                                        <input type="radio" id="daily" value="daily" v-model="timeChose" hidden>
                                                        <div class="selection_box">Daily</div>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label for="weekly" class="checkbox">
                                                        <input type="radio" id="weekly" value="weekly" v-model="timeChose" hidden>
                                                        <div class="selection_box">Weekly</div>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label for="monthly" class="checkbox">
                                                        <input type="radio" id="monthly" value="monthly" v-model="timeChose" hidden>
                                                        <div class="selection_box">Monthly</div>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label for="yearly" class="checkbox">
                                                        <input type="radio" id="yearly" value="yearly" v-model="timeChose" hidden>
                                                        <div class="selection_box">Yearly</div>
                                                    </label>
                                                </li>
                                            </ul>
                                            <div class="edit_section">
                                                <!-- <h3 v-if="timeChose == 'onetime'" class="px-2 py-2">Repeat Every <input type="number" min="0" v-model="reminderInt" class="sqr_input"> Hour(s)</h3> -->
                                                <h3 v-if="timeChose == 'onetime'" class="px-2 py-2">One-Time Occurrence</h3>
                                                <div v-if="timeChose == 'daily'" class="px-2 py-2">
                                                    <ul class="day_type">
                                                        <li>
                                                            <div class="day_wpr">
                                                                <label for="every">
                                                                    <input type="radio" id="every" value="every" v-model="everyday" hidden>
                                                                    <span><i class="fas fa-check"></i></span>
                                                                </label>
                                                                <h4>Every <input type="number" value="1" min="0" class="sqr_input"> Day(s)</h4>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="day_wpr">
                                                                <label for="work">
                                                                    <input type="radio" id="work" value="work" v-model="everyday" hidden>
                                                                    <span><i class="fas fa-check"></i></span>
                                                                </label>
                                                                <h4>Every Work Day</h4>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <div class="time_selection">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">At</label>
                                                                <div class="field_wpr">
                                                                    <Multiselect v-model="hours.value" v-bind="hours"></Multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <div class="field_wpr">
                                                                    <Multiselect v-model="clock.value" v-bind="clock"></Multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Timezone</label>
                                                                <div class="field_wpr">
                                                                    <Multiselect v-model="timezone.value" v-bind="timezone"></Multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="timeChose == 'weekly'" class="px-2 py-2">
                                                    <h3>Repeat Every <input type="number" value="1" min="0" class="sqr_input"> Week(s)</h3>
                                                    <ul class="days mt-2">
                                                        <li v-for="items in weekDays.options" :key="items.id">
                                                            <label :for="`check-${items.id}`" :class="{'active' : items.status}">
                                                                <span class="weekdays capitalize">{{items.label}}</span>
                                                                <input type="checkbox" :id="`check-${items.id}`" v-model="items.status" hidden>
                                                                <span class="tick_box"></span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    <div class="time_selection">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">At</label>
                                                                <div class="field_wpr">
                                                                    <Multiselect v-model="hours.value" v-bind="hours"></Multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <div class="field_wpr">
                                                                    <Multiselect v-model="clock.value" v-bind="clock"></Multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Timezone</label>
                                                                <div class="field_wpr">
                                                                    <Multiselect v-model="timezone.value" v-bind="timezone"></Multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="timeChose == 'monthly'" class="px-2 py-2">
                                                    <ul class="day_type">
                                                        <li>
                                                            <div class="day_wpr">
                                                                <label for="month_repeat">
                                                                    <input type="radio" id="month_repeat" value="repeat" v-model="monthlyOpt" hidden>
                                                                    <span><i class="fas fa-check"></i></span>
                                                                </label>
                                                                <h4>Repeat <input type="number" min="0" value="1" class="sqr_input">Day(s) Every <input type="number" value="1" min="0" class="sqr_input"> Month(s)</h4>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="day_wpr">
                                                                <label for="month_on">
                                                                    <input type="radio" id="month_on" value="every" v-model="monthlyOpt" hidden>
                                                                    <span><i class="fas fa-check"></i></span>
                                                                </label>
                                                                <h4>On <input type="number" min="0" value="1" class="sqr_input"> <Multiselect v-model="weekDays.value" v-bind="weekDays" class="small_box"></Multiselect> Every <input type="number" value="1" min="0" class="sqr_input"> Month(s)</h4>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <div class="time_selection">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">At</label>
                                                                <div class="field_wpr">
                                                                    <Multiselect v-model="hours.value" v-bind="hours"></Multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <div class="field_wpr">
                                                                    <Multiselect v-model="clock.value" v-bind="clock"></Multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Timezone</label>
                                                                <div class="field_wpr">
                                                                    <Multiselect v-model="timezone.value" v-bind="timezone"></Multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="timeChose == 'yearly'" class="px-2 py-2">
                                                    <ul class="day_type">
                                                        <li>
                                                            <div class="day_wpr">
                                                                <label for="year_every">
                                                                    <input type="radio" id="year_every" value="every" v-model="yearlyOpt" hidden>
                                                                    <span><i class="fas fa-check"></i></span>
                                                                </label>
                                                                <h4>Every <input type="number" min="0" value="1" class="sqr_input">Day(s) <Multiselect v-model="month.value" v-bind="month" class="small_box"></Multiselect> Month</h4>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="day_wpr">
                                                                <label for="year_on">
                                                                    <input type="radio" id="year_on" value="yearOn" v-model="yearlyOpt" hidden>
                                                                    <span><i class="fas fa-check"></i></span>
                                                                </label>
                                                                <h4>On <input type="number" min="0" value="1" class="sqr_input"> <Multiselect v-model="weekDays.value" v-bind="weekDays" class="small_box"></Multiselect> Of <Multiselect v-model="month.value" v-bind="month" class="small_box"></Multiselect> Month</h4>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <div class="time_selection">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">At</label>
                                                                <div class="field_wpr">
                                                                    <Multiselect v-model="hours.value" v-bind="hours"></Multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <div class="field_wpr">
                                                                    <Multiselect v-model="clock.value" v-bind="clock"></Multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Timezone</label>
                                                                <div class="field_wpr">
                                                                    <Multiselect v-model="timezone.value" v-bind="timezone"></Multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="edit_section mt-4 mb-2">
                                                <h3 class="sub_header mt-2">Until</h3>
                                                <ul class="day_type">
                                                    <li>
                                                        <div class="day_wpr">
                                                            <label for="ongoing">
                                                                <input type="radio" id="ongoing" value="ongoing" v-model="until" hidden>
                                                                <span><i class="fas fa-check"></i></span>
                                                            </label>
                                                            <h4>Ongoing</h4>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="day_wpr">
                                                            <label for="after">
                                                                <input type="radio" id="after" value="after" v-model="until" hidden>
                                                                <span><i class="fas fa-check"></i></span>
                                                            </label>
                                                            <h4>After <input type="number" min="0" value="1" class="sqr_input"> Occurances</h4>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="day_wpr">
                                                            <label for="end">
                                                                <input type="radio" id="end" value="end" v-model="until" hidden>
                                                                <span><i class="fas fa-check"></i></span>
                                                            </label>
                                                            <h4>End On <input type="date" class="date_input" v-model="untilDate"></h4>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="workoutStep == 2">
                            <div class="section_content w-100" ref="formsetting">
                                <div class="addblock_section">
                                    <button type="button" class="add_btn pointer ml-auto mr-auto" @click="newBlock = true;" v-click-outside="closeBlockTypes"><i class="fas fa-plus"></i>Add Block</button>
                                    <div class="add_types" v-if="newBlock">
                                        <div class="title">Select Block Type</div>
                                        <ul>
                                            <li @click="createNewBlock('Warm-up')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">Warm-up
                                                </div>
                                            </li>
                                            <li @click="createNewBlock('Hypertrophy')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">Hypertrophy
                                                </div>
                                            </li>
                                            <li @click="createNewBlock('Strength')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">Strength
                                                </div>
                                            </li>
                                            <li @click="createNewBlock('Power')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">Power
                                                </div>
                                            </li>
                                            <li @click="createNewBlock('Tabata')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">Tabata
                                                </div>
                                            </li>
                                            <li @click="createNewBlock('HIIT')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">HIIT
                                                </div>
                                            </li>
                                            <li @click="createNewBlock('WOD')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">WOD
                                                </div>
                                            </li>
                                            <li @click="createNewBlock('Cardio')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">Cardio
                                                </div>
                                            </li>
                                            <li @click="createNewBlock('Recovery')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">Recovery
                                                </div>
                                            </li>
                                            <li @click="createNewBlock('Mind')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">Mind  
                                                </div>
                                            </li>
                                            <li @click="createNewBlock('Smart Block')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">Smart Block
                                                </div>
                                            </li>
                                            <!-- <li @click="createNewBlock('Progression')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">Progression
                                                </div>
                                            </li> -->
                                            <!-- <li @click="createNewBlock('Finisher')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">Finisher
                                                </div>
                                            </li> -->
                                            <!-- <li @click="createNewBlock('Rest Period')">
                                                <div class="card_item">
                                                    <img src="@/assets/images/template.svg" alt="">Rest Period
                                                </div>
                                            </li> -->
                                        </ul>
                                    </div>
                                </div>
                                <draggable v-model="blockList" tag="ul" :animation="200" class="block_list">
                                    <template #item="{ element, index }">
                                        <li @click="selectBlockPanel = index;" :class="{'circuit' : element.block_type == 2}">
                                            <div class="section_header move">
                                                <h2>
                                                    <div v-if="!is_edit" @click="is_edit = true;">{{ element.block_name }}</div>
                                                    <input type="text" v-model="element.block_name" v-if="is_edit"><span class="save_btn" v-if="is_edit" @click="is_edit = false;">Save</span>
                                                    <a v-if="element.block_type == 2">
                                                        {{element.amrap ? 'AMRAP' : `${element.block_round} Rounds`}} 
                                                        <div class="quick_info" v-if="element.amrap">As Many Round As Possible</div>
                                                        <label v-if="element.block_rest_min || element.block_rest_sec">| {{element.block_rest_min ? `${element.block_rest_min} Min` : ''}} {{element.block_rest_sec ? `${element.block_rest_sec} Sec Rest Interval` : ''}} </label>
                                                        <label v-if="element.has_duration && (element.block_duration_min || element.block_duration_sec)"> | {{ element.block_duration_min ? `${element.block_duration_min} Min Duration` : ''  }} {{ element.block_duration_sec ? `${element.block_duration_sec} Sec Duration` : ''  }}</label>
                                                    </a>
                                                </h2>
                                                <div class="block_actions">
                                                    <label for="display_header" class="switch_option capsule_btn" v-if="element.block_name == 'Rest Period'">
                                                        <h5>{{activeRest ? 'Active' : 'Inactive'}} Rest</h5>
                                                        <input type="checkbox" id="display_header" :true-value="1" :false-value="0" v-model="activeRest" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <!-- <button type="button" class="up_down"><i class="fas fa-chevron-up"></i></button>
                                                    <button type="button" class="up_down"><i class="fas fa-chevron-down"></i></button> -->
                                                    <div type="button" class="block_setting" v-if="element.block_name !== 'Rest Period'">
                                                        <span @click="blockSetting($event)"><i class="fas fa-cog"></i></span>
                                                        <div class="dropdown_wpr">
                                                            <label :for="`progression-${index}`" class="switch_option capsule_btn p-0 mt-2 mb-4 border-0">
                                                                <h5 class="large mb-0">Perform as a progression</h5>
                                                                <input type="radio" :id="`progression-${index}`" :value="1" v-model="element.block_type" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                            <label :for="`circuit-${index}`" class="switch_option capsule_btn p-0 mt-0 mb-2 border-0">
                                                                <h5 class="large mb-0">Perform as a circuit</h5>
                                                                <input type="radio" :id="`circuit-${index}`" :value="2" v-model="element.block_type" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                            <ul class="setting_wpr" v-if="element.block_type == 2">
                                                                <li>Rounds
                                                                    <div class="form_grp py-1 mt-1" v-if="!element.amrap">
                                                                        <div class="group_item">
                                                                            <div class="field_wpr">
                                                                                <input type="number" min="0" v-model="element.block_round">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <label for="amrap" class="switch_option capsule_btn p-0 mt-2 mb-1 border-0">
                                                                        <h5 class="large mb-0">AMRAP</h5>
                                                                        <input type="checkbox" id="amrap" :true-value="1" :false-value="0" v-model="element.amrap" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                </li>
                                                                <li>
                                                                    Rest interval
                                                                    <div class="form_grp py-1 mt-1">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Minutes</label>
                                                                            <div class="field_wpr">
                                                                                <input type="number" min="0" v-model="element.block_rest_min">
                                                                            </div>
                                                                        </div>
                                                                        <div class="group_item">
                                                                            <label class="input_label">Seconds</label>
                                                                            <div class="field_wpr">
                                                                                <input type="number" min="0" v-model="element.block_rest_sec">
                                                                                <!-- <Multiselect v-model="element.blockRestUnit.value" v-bind="element.blockRestUnit"></Multiselect> -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <label for="has_duration" class="switch_option capsule_btn p-0 mt-0 mb-1 border-0">
                                                                        Duration
                                                                        <input type="checkbox" id="has_duration" :true-value="1" :false-value="0" v-model="element.has_duration" hidden>
                                                                        <div><span></span></div>
                                                                    </label> 
                                                                    <div class="form_grp py-1" v-if="element.has_duration">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Minutes</label>
                                                                            <div class="field_wpr">
                                                                                <input type="number" min="0" v-model="element.block_duration_min">
                                                                            </div>
                                                                        </div>
                                                                        <div class="group_item">
                                                                            <label class="input_label">Seconds</label>
                                                                            <div class="field_wpr">
                                                                                <input type="number" min="0" v-model="element.block_duration_sec">
                                                                                <!-- <Multiselect v-model="element.blockDurationUnit.value" v-bind="element.blockDurationUnit"></Multiselect> -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <label :for="`smart_block-${index}`" class="switch_option capsule_btn pt-2 pb-2 m-0 border-0">
                                                                <h5 class="large mb-0">Save as Smart Block</h5>
                                                                <input type="checkbox" :id="`smart_block-${index}`" :true-value="1" :false-value="0" v-model="element.smart_block" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                            <div class="form_grp py-1 m-0" v-if="element.smart_block == 1">
                                                                <div class="group_item">
                                                                    <label class="input_label">Name</label>
                                                                    <div class="field_wpr has_suffix">
                                                                        <input type="text" v-model="element.block_name">
                                                                        <span class="suffix" @click="addToSmart(element)">Save</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <label class="sec_label mt-2">Work Interval</label>
                                                            <ul class="setting_wpr">
                                                                <li>
                                                                    <label for="work_int_manual" class="switch_option capsule_btn p-0 mb-3 border-0">
                                                                        <h5 class="large mb-0">Manually Start Timer</h5>
                                                                        <input type="radio" id="work_int_manual" :value="1" v-model="element.work_interval" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                    <label for="work_int_auto" class="switch_option capsule_btn p-0 mb-2 border-0">
                                                                        <h5 class="large mb-0">Auto Start Timer</h5>
                                                                        <input type="radio" id="work_int_auto" :value="2" v-model="element.work_interval" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                            <label class="sec_label">Rest Interval</label>
                                                            <ul>
                                                                <li>
                                                                    <label for="rest_int_manual" class="switch_option capsule_btn p-0 mb-3 border-0">
                                                                        <h5 class="large mb-0">Manually Start Timer</h5>
                                                                        <input type="radio" id="rest_int_manual" :value="1" v-model="element.rest_interval" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                    <label for="rest_int_auto" class="switch_option capsule_btn p-0 mb-2 border-0">
                                                                        <h5 class="large mb-0">Auto Start Timer</h5>
                                                                        <input type="radio" id="rest_int_auto" :value="2" v-model="element.rest_interval" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                            <label class="sec_label">Block Interval</label>
                                                            <ul>
                                                                <li>
                                                                    <label for="block_int_manual" class="switch_option capsule_btn p-0 mb-3 border-0">
                                                                        <h5 class="large mb-0">Manually Start Timer</h5>
                                                                        <input type="radio" id="block_int_manual" :value="1" v-model="element.block_interval" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                    <label for="block_int_auto" class="switch_option capsule_btn p-0 mb-2 border-0">
                                                                        <h5 class="large mb-0">Auto Start Timer</h5>
                                                                        <input type="radio" id="block_int_auto" :value="2" v-model="element.block_interval" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div type="button" class="block_setting" v-else>
                                                        <span @click="editRest = !editRest"><i class="fas fa-cog"></i></span>
                                                        <div class="edit_rest" v-if="editRest">
                                                            <div class="title">Edit Rest Period</div>
                                                            <div class="setting_wpr">
                                                                <div class="form_grp p-0">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Minutes</label>
                                                                        <div class="field_wpr">
                                                                            <input type="number" min="0" v-model="rest_min">
                                                                        </div>
                                                                    </div>
                                                                    <div class="group_item">
                                                                        <label class="input_label">Seconds</label>
                                                                        <div class="field_wpr">
                                                                            <input type="number" min="0" v-model="rest_sec">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp pt-3 pb-0">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Color</label>
                                                                        <Field autocomplete="off" name="circle_textcolor" type="text">
                                                                            <color-picker v-model="circle_txt_color"/>
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp pt-3 pb-0">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Background Color</label>
                                                                        <Field autocomplete="off" name="circle_bgcolor" type="text">
                                                                            <color-picker v-model="circle_bg_color"/>
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="action_wpr">
                                                                <button type="button" class="btn cancel_btn" @click="editRest = false;">Cancel</button>
                                                                <button type="button" class="btn save_btn" @click="editRest = false;">Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button" class="up_down" @click="copySectionBlock(element, index)"><i class="far fa-copy"></i></button>
                                                    <button type="button" class="delete_btn" @click="deleteSectionBlock(index)"><i class="fas fa-trash-alt danger"></i></button>
                                                </div>
                                            </div>
                                            <ul v-if="element.block_name == 'Rest Period' && !activeRest">
                                                <li class="rest_list">
                                                    <div class="rest_circle" :style="`color:${circle_txt_color}; background:${circle_bg_color};`"><span>{{rest_min}}:{{rest_sec}}</span>Minutes</div>
                                                </li>
                                            </ul>
                                            <div class="block_panel" :class="{active : selectBlockPanel == index}" v-else :style="`background:${element.block_name == 'Rest Period' ? circle_bg_color : ''};`">
                                                <ul v-if="!element.items.length">
                                                    <li class="add_item">
                                                        <button type="button" @click="shakeFunc"><i class="fas fa-plus"></i>Add Exercise</button>
                                                    </li>
                                                </ul>
                                                <draggable v-model="element.items" tag="ul" :animation="200" v-else>
                                                    <template #item="{ element : subelement, index : i }">
                                                        <li class="move" :class="{ selected : selectedLibraryItems.includes(`item-${i}`) }">
                                                            <h6 :style="`color: ${element.block_name == 'Rest Period' && activeRest ? circle_txt_color : ''}`" @click="blockEditFunc(subelement.link, subelement.name, element.block_type == 2, element.block_rest_min, element.block_rest_sec)">{{ subelement.name }}</h6>
                                                            <div class="video_wpr">
                                                                <div class="video-overlay" @click="show_video = true;"></div>
                                                                <iframe :src="subelement.link" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                                            </div>
                                                            <h6 :style="`color: ${element.block_name == 'Rest Period' && activeRest ? circle_txt_color : ''}`" @click="blockEditFunc(subelement.link, subelement.name, element.block_type == 2, element.block_rest_min, element.block_rest_sec)">
                                                                <span class="v_tag" v-if="exercise.variable">V</span>
                                                                <span v-if="element.block_type !== 2">{{ exercise.sets }} sets |</span>
                                                                <span v-if="measures.value !== 'Using Time'">&nbsp;{{ exercise.reps }} reps |</span><span v-if="measures.value == 'Using Time' && exercise.time_min">&nbsp;{{ exercise.time_min }} min</span><span v-if="measures.value == 'Using Time' && exercise.time_sec">&nbsp;{{ exercise.time_sec }} sec |</span>
                                                                <span v-if="exercise.units.value == '1RM'">&nbsp;30% 1RM |</span><span v-else-if="exercise.units.value == 'BW'">&nbsp;Bodyweight |</span><span v-else>&nbsp;{{ exercise.load }} {{ exercise.units.value.toLowerCase() }} </span>
                                                                <!-- <span v-if="element.block_type !== 2 && exercise.has_rest && exercise.rest_min">&nbsp;{{ exercise.rest_min }} min</span><span v-if="element.block_type !== 2 && exercise.has_rest && exercise.rest_sec">&nbsp;{{ exercise.rest_sec }} sec</span>
                                                                <span v-if="!exercise.has_rest && element.block_type !== 2">&nbsp;No</span> &nbsp;<span v-if="element.block_type !== 2">rest</span> -->
                                                            </h6>
                                                            <div class="block_action">
                                                                <button type="button" @click="deleteWorkout(index, i)"><i class="fas fa-trash"></i></button>
                                                            </div>
                                                            <div class="circuit">
                                                                <i class="fas fa-arrow-alt-circle-right"></i>
                                                                <a v-if="element.block_rest_min || element.block_rest_sec"><a v-if="element.block_rest_min">{{element.block_rest_min}} Min</a> <a v-if="element.block_rest_sec">{{element.block_rest_sec}} Sec</a></a>
                                                            </div>
                                                        </li>
                                                    </template>
                                                </draggable>
                                            </div>
                                        </li>
                                    </template>
                                </draggable>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="workoutStep == 3">
                            <div class="section_content w-100 open_area">
                                <div class="section_header">
                                    <h2>Public Page</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp p-0 mt-1 mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Public Url</label>
                                                <div class="field_wpr has_suffix">
                                                    <input type="text" placeholder="https://www.clientportal.com">
                                                    <span class="suffix">Copy</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="hdrsetting" @click="goTo('hdrsection')">
                                <div class="section_header">
                                    <h2>Header</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_header ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_header ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <label for="display_header" class="switch_option capsule_btn">
                                            <h5>Show</h5>
                                            <input type="checkbox" id="display_header" :true-value="1" :false-value="0" v-model="form.public_settings.display_header" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr outer" v-show="form.public_settings.display_header">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Company Name <a class="reset-default" @click="form.public_settings.company_branding = defaultForm.public_settings.company_branding">Reset to default</a></label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.company_branding }">
                                                            <Field autocomplete="off" type="text" name="company_branding" v-model="form.public_settings.company_branding" placeholder="Company Name" />
                                                        </div>
                                                        <ErrorMessage name="company_branding" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item mt-3">
                                                        <label class="input_label">Header Background Color <a class="reset-default" @click="form.public_settings.header_bgcolor = defaultForm.public_settings.header_bgcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="header_bgcolor" type="text" label="header background color">
                                                            <color-picker v-model="form.public_settings.header_bgcolor"/>
                                                        </Field>
                                                        <ErrorMessage name="header_bgcolor" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item mt-3">
                                                        <label class="input_label">Header Text Color <a class="reset-default" @click="form.public_settings.header_textcolor = defaultForm.public_settings.header_textcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="header_textcolor" type="text" label="header text color">
                                                            <color-picker v-model="form.public_settings.header_textcolor"/>
                                                        </Field>
                                                        <ErrorMessage name="header_textcolor" class="text-danger" />
                                                    </div>
                                                </div>
                                                <ul class="tab_sec mt-2">
                                                    <li @click="headerTab = 'logo'" :class="{ active: headerTab === 'logo' }">Header Logo</li>
                                                    <li @click="headerTab = 'author'" :class="{ active: headerTab === 'author' }">Header Author Image</li>
                                                </ul>
                                                <div class="setting_wpr pt-4">
                                                    <div v-if="headerTab === 'logo'">
                                                        <label for="show_logo" class="switch_option capsule_btn py-4">
                                                            <h5 class="large">Display Logo</h5>
                                                            <input type="checkbox" id="show_logo" :true-value="1" :false-value="0" v-model="form.public_settings.display_logo" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                        <div v-if="form.public_settings.display_logo">
                                                            <image-library image-type="public-logo" upload-text="Logo" v-model="form.public_settings.logo" />
                                                        </div>
                                                    </div>
                                                    <div v-if="headerTab === 'author'">
                                                        <label for="coach_img" class="switch_option capsule_btn py-4">
                                                            <h5 class="large">Display Author</h5>
                                                            <input type="checkbox" id="coach_img" :true-value="1" :false-value="0" v-model="form.public_settings.display_author" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                        <div v-if="form.public_settings.display_author">
                                                            <image-library image-type="avatar" :is-avatar="true" upload-text="Image" v-model="form.public_settings.author" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="section_content w-100" ref="cvrsetting"  @click="goTo('cvrsection')">
                                <div class="section_header">
                                    <h2>Cover</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_cover ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_cover ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <label for="cover" class="switch_option capsule_btn">
                                            <h5>Show
                                                <span v-if="!form.public_settings.small_cover">Upload a small screen cover for a better view</span>
                                            </h5>
                                            <input type="checkbox" id="cover" :true-value="1" :false-value="0" v-model="form.public_settings.display_cover" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <Transition duration="550" name="nested">
                                            <div v-if="form.public_settings.display_cover">
                                                <div class="cover_type">
                                                    <ul class="type_list">
                                                        <li>
                                                            <label for="clr_text">
                                                                <input type="radio" id="clr_text" :value="1" v-model="form.public_settings.cover_type" hidden>
                                                                <img src="@/assets/images/background.svg" alt="">
                                                                <h5>Color + Text</h5>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="img_text">
                                                                <input type="radio" id="img_text" :value="2" v-model="form.public_settings.cover_type" hidden>
                                                                <img src="@/assets/images/picture.svg" alt="">
                                                                <h5>Image + Text</h5>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="img_only">
                                                                <input type="radio" id="img_only" :value="3" v-model="form.public_settings.cover_type" hidden>
                                                                <img src="@/assets/images/picture.svg" alt="">
                                                                <h5>Image Only</h5>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.cover_type !== 3">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Headline
                                                                <div class="flex-center">
                                                                    <button type="button" class="reset-default" @click="form.public_settings.headline = defaultForm.public_settings.headline">Reset to default</button>
                                                                    <button type="button" class="px-1" @click="headlineSetting = !headlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                                </div>
                                                            </label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.headline }">
                                                                <Field autocomplete="off" type="text" name="headline" v-model="form.public_settings.headline" placeholder="Compelling Headline Goes Here" />
                                                            </div>
                                                            <ErrorMessage name="headline" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div v-if="headlineSetting">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Family <a class="reset-default" @click="form.headline_setting.font_family = defaultForm.headline_setting.font_family">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.headline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Weight <a class="reset-default" @click="form.headline_setting.font_weight = defaultForm.headline_setting.font_weight">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.headline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Size <a class="reset-default" @click="form.headline_setting.font_size = defaultForm.headline_setting.font_size">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="nofill" type="number" name="headline_setting_font_size" v-model="form.headline_setting.font_size" min="0" max="100" placeholder="90" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Color <a class="reset-default" @click="form.headline_setting.font_color = defaultForm.headline_setting.font_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="headline_setting_cover_textcolor" v-model="form.headline_setting.font_color" type="text" label="element color">
                                                                    <color-picker v-model="form.headline_setting.font_color" :classes="{ 'has-error': errors.headline_setting_cover_textcolor }" />
                                                                </Field>
                                                                <ErrorMessage name="headline_setting_cover_textcolor" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="mt-4">
                                                            <label for="headline_shadow" class="switch_option capsule_btn">
                                                                <h5 class="large">Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                                <input type="checkbox" id="headline_shadow" :true-value="1" :false-value="0" v-model="form.headline_setting.has_shadow" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                            <div v-if="form.headline_setting.has_shadow">
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-x <a class="reset-default" @click="form.headline_setting.shadow_x = defaultForm.headline_setting.shadow_x">Reset to default</a></label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="headline_setting_shadow_x" v-model="form.headline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-y <a class="reset-default" @click="form.headline_setting.shadow_y = defaultForm.headline_setting.shadow_y">Reset to default</a></label>
                                                                        <div class="field_wpr ">
                                                                            <Field autocomplete="nofill" type="number" name="headline_setting_shadow_y" v-model="form.headline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-3">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Blur-Radius <a class="reset-default" @click="form.public_settings.shadow_blur = defaultForm.public_settings.shadow_blur">Reset to default</a></label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="headline_setting_shadow_blur" v-model="form.headline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Shadow Color <a class="reset-default" @click="form.public_settings.shadow_color = defaultForm.public_settings.shadow_color">Reset to default</a></label>
                                                                        <Field autocomplete="off" name="headline_setting_shadow_color" v-model="form.headline_setting.shadow_color" type="text" label="element color">
                                                                            <color-picker v-model="form.headline_setting.shadow_color" :classes="{ 'has-error': errors.headline_setting_shadow_color }" />
                                                                        </Field>
                                                                        <ErrorMessage name="headline_setting_shadow_color" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.cover_type !== 3">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Sub Headline
                                                                <div class="flex-center">
                                                                    <button type="button" class="reset-default" @click="form.public_settings.subheadline = defaultForm.public_settings.subheadline">Reset to default</button>
                                                                    <button type="button" class="px-1" @click="subheadlineSetting = !subheadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                                </div>
                                                            </label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.sub_headline }">
                                                                <Field autocomplete="off" type="text" name="sub_headline" v-model="form.public_settings.subheadline" placeholder="This can be edited or changed to a cover image by clicking here" />
                                                            </div>
                                                            <ErrorMessage name="sub_headline" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div v-if="subheadlineSetting">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Family <a class="reset-default" @click="form.subheadline_setting.font_family = defaultForm.subheadline_setting.font_family">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.subheadline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Weight <a class="reset-default" @click="form.subheadline_setting.font_weight = defaultForm.subheadline_setting.font_weight">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.subheadline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Size <a class="reset-default" @click="form.subheadline_setting.font_size = defaultForm.subheadline_setting.font_size">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="nofill" type="number" name="subheadline_setting_font_size" v-model="form.subheadline_setting.font_size" min="0" max="100" placeholder="90" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Color <a class="reset-default" @click="form.subheadline_setting.font_color = defaultForm.subheadline_setting.font_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="subheadline_setting_font_color" v-model="form.subheadline_setting.font_color" type="text" label="element color">
                                                                    <color-picker v-model="form.subheadline_setting.font_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                                                </Field>
                                                                <ErrorMessage name="subheadline_setting_font_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="mt-4">
                                                            <label for="subheadline_shadow" class="switch_option capsule_btn">
                                                                <h5 class="large">Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                                <input type="checkbox" id="subheadline_shadow" :true-value="1" :false-value="0" v-model="form.subheadline_setting.has_shadow" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                            <div v-if="form.subheadline_setting.has_shadow">
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-x <a class="reset-default" @click="form.subheadline_setting.shadow_x = defaultForm.subheadline_setting.shadow_x">Reset to default</a></label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_x" v-model="form.subheadline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-y <a class="reset-default" @click="form.subheadline_setting.shadow_y = defaultForm.subheadline_setting.shadow_y">Reset to default</a></label>
                                                                        <div class="field_wpr ">
                                                                            <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_y" v-model="form.subheadline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-3">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Blur-Radius <a class="reset-default" @click="form.subheadline_setting.shadow_blur = defaultForm.subheadline_setting.shadow_blur">Reset to default</a></label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_blur" v-model="form.subheadline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Shadow Color <a class="reset-default" @click="form.subheadline_setting.shadow_color = defaultForm.subheadline_setting.shadow_color">Reset to default</a></label>
                                                                        <Field autocomplete="off" name="subheadline_setting_shadow_color" v-model="form.subheadline_setting.shadow_color" type="text" label="element color">
                                                                            <color-picker v-model="form.subheadline_setting.shadow_color" :classes="{ 'has-error': errors.subheadline_setting_shadow_color }" />
                                                                        </Field>
                                                                        <ErrorMessage name="subheadline_setting_shadow_color" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr mt-2" v-if="form.public_settings.cover_type !== 1">
                                                    <h3 class="sub_header mt-1">Cover Image</h3>
                                                    <image-library image-type="public-cover" upload-text="Cover" v-model="form.public_settings.cover" />
                                                    <div class="border-bottom">
                                                        <label for="small_image" class="switch_option capsule_btn">
                                                            <h5 class="large">Small Screen Image</h5>
                                                            <input type="checkbox" id="small_image" :true-value="1" :false-value="0" v-model="form.public_settings.has_small_cover" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                        <image-library v-if="form.public_settings.has_small_cover" image-type="public-cover-small" upload-text="Cover" v-model="form.public_settings.small_cover" />
                                                    </div>
                                                    <label for="overlay" class="switch_option capsule_btn">
                                                        <h5 class="large">Add Image Overlay</h5>
                                                        <input type="checkbox" id="overlay" :true-value="1" :false-value="0" v-model="form.public_settings.has_cover_overlay" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <div v-if="form.public_settings.has_cover_overlay" class="setting_wpr mt-2">
                                                        <div class="color_container mt-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Overlay Color <a class="reset-default" @click="form.public_settings.overlay_color = defaultForm.public_settings.overlay_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="overlay_color" v-model="form.public_settings.overlay_color" type="text" label="element color">
                                                                    <color-picker v-model="form.public_settings.overlay_color" />
                                                                </Field>
                                                                <ErrorMessage name="overlay_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Overlay opacity <a class="reset-default" @click="form.public_settings.overlay_opacity = defaultForm.public_settings.overlay_opacity">Reset to default</a></label>
                                                                <div class="field_wpr" :class="{ 'has-error': errors.overlay_opacity }">
                                                                    <Field autocomplete="off" type="number" name="overlay_opacity" v-model="form.public_settings.overlay_opacity" min="0" max="100" rules="min:0|max:100" placeholder="68" />
                                                                </div>
                                                                <ErrorMessage name="overlay_opacity" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.cover_type === 1">
                                                    <div class="group_item">
                                                        <label class="input_label">Cover Background <a class="reset-default" @click="form.public_settings.cover_color = defaultForm.public_settings.cover_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="cover_background_color" v-model="form.public_settings.cover_color" type="text" label="element color">
                                                            <color-picker v-model="form.public_settings.cover_color" />
                                                        </Field>
                                                        <ErrorMessage name="cover_background_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div> -->
                            
                            <div class="section_content w-100">
                                <div class="section_header"  @click="goTo('expvidsection')">
                                    <h2>Workout Explainer Video</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_explainer_video ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_explainer_video ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <label for="explain_video" class="switch_option capsule_btn">
                                            <h5>Show</h5>
                                            <input type="checkbox" id="explain_video" :true-value="1" :false-value="0" v-model="form.public_settings.display_explainer_video" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr mt-3" v-show="form.public_settings.display_explainer_video">
                                                <div class="border-bottom pb-2">
                                                    <label for="video_url" class="switch_option capsule_btn border-0">
                                                        <h5 class="large">Video Link (youtube, vimeo, wistia, screencast-o-matic, loom)</h5>
                                                        <input type="radio" id="video_url" value="0" v-model="form.public_settings.explainer_video_type" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <div class="setting_wpr mb-2" v-if="form.public_settings.explainer_video_type == 0">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.explainer_video }">
                                                                    <Field autocomplete="off" type="text" name="explainer_video" v-model="form.public_settings.explainer_video" placeholder="Video Link Goes Here..." rules="url" label="explainer video" />
                                                                    <span class="prefix">URL</span>
                                                                </div>
                                                                <!-- <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small> -->
                                                                <ErrorMessage name="explainer_video" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="py-2">
                                                    <label for="embed_video" class="switch_option capsule_btn border-0">
                                                        <h5 class="large">Embeded Code (youtube, vimeo, wistia, screencast-o-matic, loom)</h5>
                                                        <input type="radio" id="embed_video" value="1" v-model="form.public_settings.explainer_video_type" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <div class="setting_wpr" v-if="form.public_settings.explainer_video_type == 1">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.explainer_embed }">
                                                                    <Field autocomplete="off" type="text" name="explainer_embed" v-model="form.public_settings.explainer_embed" label="explainer embed code" :class="{ 'has-error': errors.explainer_embed }">
                                                                        <textarea cols="30" rows="10" v-model="form.public_settings.explainer_embed" placeholder="Embed code goes here.."></textarea>
                                                                    </Field>
                                                                </div>
                                                                <ErrorMessage name="explainer_embed" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Color</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Accent Color <a class="reset-default" @click="form.public_settings.element_color = defaultForm.public_settings.element_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="label_bg_color" type="text" label="label background color">
                                                        <color-picker v-model="form.public_settings.element_color" />
                                                    </Field>
                                                    <ErrorMessage name="label_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Text Color <a class="reset-default" @click="form.public_settings.element_text_color = defaultForm.public_settings.element_text_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="label_text_color" type="text" label="label text color">
                                                        <color-picker v-model="form.public_settings.element_text_color" />
                                                    </Field>
                                                    <ErrorMessage name="label_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Block Background Color <a class="reset-default" @click="form.public_settings.block_bg_color = defaultForm.public_settings.block_bg_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="label_bg_color" type="text" label="label background color">
                                                        <color-picker v-model="form.public_settings.block_bg_color" />
                                                    </Field>
                                                    <ErrorMessage name="label_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Timer Color <a class="reset-default" @click="form.public_settings.timer_color = defaultForm.public_settings.timer_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="label_bg_color" type="text" label="label background color">
                                                        <color-picker v-model="form.public_settings.timer_color" />
                                                    </Field>
                                                    <ErrorMessage name="label_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Submit Button</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div ref="subbtnsetting"  @click="goTo('subbtnsection')">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Button Text <a class="reset-default" @click="form.public_settings.submit_btn_text = defaultForm.public_settings.submit_btn_text">Reset to default</a></label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.name }">
                                                            <Field autocomplete="off" type="text" name="name" v-model="form.public_settings.submit_btn_text" placeholder="ex: Submit Progress" rules="required" />
                                                        </div>
                                                        <ErrorMessage name="name" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Button Color <a class="reset-default" @click="form.public_settings.submit_btn_bgcolor = defaultForm.public_settings.submit_btn_bgcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="button_bg_color" type="text" label="button background color">
                                                            <color-picker v-model="form.public_settings.submit_btn_bgcolor" />
                                                        </Field>
                                                        <ErrorMessage name="button_bg_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Text Color <a class="reset-default" @click="form.public_settings.submit_btn_textcolor = defaultForm.public_settings.submit_btn_textcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="button_text_color" type="text" label="button text color">
                                                            <color-picker v-model="form.public_settings.submit_btn_textcolor" />
                                                        </Field>
                                                        <ErrorMessage name="button_text_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="section_content w-100" ref="thankyousetting" @click="goTo('thankyousection')">
                                <div class="section_header">
                                    <h2>Thank you</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Background Color <a class="reset-default" @click="form.public_settings.thankyou_bgcolor = defaultForm.public_settings.thankyou_bgcolor">Reset to default</a></label>
                                                    <Field autocomplete="off" name="thankyou_bgcolor" v-model="form.public_settings.thankyou_bgcolor" type="text" label="element color">
                                                        <color-picker v-model="form.public_settings.thankyou_bgcolor" :classes="{ 'has-error': errors.thankyou_bgcolor }" />
                                                    </Field>
                                                    <ErrorMessage name="thankyou_bgcolor" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Text Color <a class="reset-default" @click="form.public_settings.thankyou_textcolor = defaultForm.public_settings.thankyou_textcolor">Reset to default</a></label>
                                                    <Field autocomplete="off" name="thankyou_textcolor" v-model="form.public_settings.thankyou_textcolor" type="text" label="element color">
                                                        <color-picker v-model="form.public_settings.thankyou_textcolor" :classes="{ 'has-error': errors.thankyou_textcolor }" />
                                                    </Field>
                                                    <ErrorMessage name="thankyou_textcolor" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="section_content w-100" ref="ftrsetting">
                                <div class="section_header">
                                    <h2>Footer</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_footer ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_footer ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1 mb-2">
                                        <label for="has-login-footer" class="switch_option capsule_btn">
                                            <h5>Show</h5>
                                            <input type="checkbox" id="has-login-footer" :true-value="1" :false-value="0" v-model="form.public_settings.display_footer" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <Transition duration="550" name="nested">
                                            <div class="mt-4 outer" v-show="form.public_settings.display_footer">
                                                <label for="facebook" class="switch_option capsule_btn p-0 border-0">
                                                    <h5 class="large">Facebook</h5>
                                                    <input type="checkbox" id="facebook" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_facebook" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_facebook">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_facebook" v-model="form.public_settings.footer_facebook" placeholder="Facebook link here" rules="url|validate_url:facebook" label="facebook" />
                                                            </div>
                                                            <ErrorMessage name="footer_facebook" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label for="twitter" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                    <h5 class="large">Twitter</h5>
                                                    <input type="checkbox" id="twitter" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_twitter" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_twitter">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_twitter" v-model="form.public_settings.footer_twitter" placeholder="Twitter link here" rules="url|validate_url:twitter" label="twitter" />
                                                            </div>
                                                            <ErrorMessage name="footer_twitter" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label for="instagram" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                    <h5 class="large">Instagram</h5>
                                                    <input type="checkbox" id="instagram" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_instagram" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_instagram">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_instagram" v-model="form.public_settings.footer_instagram" placeholder="Instagram link here" rules="url|validate_url:instagram" label="instagram" />
                                                            </div>
                                                            <ErrorMessage name="footer_instagram" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr">
                                                    <div class="group_item mt-3">
                                                        <label class="input_label">Icons Color <a class="reset-default" @click="form.public_settings.footer_textcolor = defaultForm.public_settings.footer_textcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="footer_textcolor" v-model="form.public_settings.footer_textcolor" type="text" label="element color">
                                                            <color-picker v-model="form.public_settings.footer_textcolor"/>
                                                        </Field>
                                                        <ErrorMessage name="footer_textcolor" class="text-danger" />
                                                    </div>
                                                    <div class="group_item mt-3">
                                                        <label class="input_label">Background Color <a class="reset-default" @click="form.public_settings.footer_bgcolor = defaultForm.public_settings.footer_bgcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="footer_bgcolor" v-model="form.public_settings.footer_bgcolor" type="text" label="element color">
                                                            <color-picker v-model="form.public_settings.footer_bgcolor"/>
                                                        </Field>
                                                        <ErrorMessage name="footer_bgcolor" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                    <div class="color_container mt-2 mb-2" v-show="form.public_settings.display_footer">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Copyright Text</label>
                                                    <div class="field_wpr m-0">
                                                        <Field autocomplete="off" type="text" name="footer_company" v-model="form.public_settings.footer_company" :placeholder="`© ${new Date().getFullYear()} - ${user.businessname ? user.businessname : 'Company Name'}`" />
                                                    </div>
                                                    <ErrorMessage name="footer_company" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Terms Link</label>
                                                    <div class="field_wpr has_prefix m-0">
                                                        <Field autocomplete="off" type="text" name="footer_terms" v-model="form.public_settings.footer_terms" placeholder="https://superfitcoaching.com" rules="url" label="term link" />
                                                        <span class="prefix">URL</span>
                                                    </div>
                                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                    <ErrorMessage name="footer_terms" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Privacy Link</label>
                                                    <div class="field_wpr has_prefix m-0">
                                                        <Field autocomplete="off" type="text" name="footer_policy" v-model="form.public_settings.footer_policy" placeholder="https://superfitcoaching.com" rules="url" label="privacy link" />
                                                        <span class="prefix">URL</span>
                                                    </div>
                                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                    <ErrorMessage name="footer_policy" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="workoutStep == 4">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Notifications</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <swiper :slidesPerView="'auto'" :spaceBetween="0" :navigation='true' class="tab_slider">
                                            <swiper-slide><span @click="reminderTab = 'reminders'" :class="{ active: reminderTab === 'reminders' }">Client Reminders <label class="tag" :style="`background: #${form.reminder.has_reminder ? '2f7eed' : 'f2a31d'}`">{{ form.reminder.has_reminder ? 'On' : 'Off' }}</label></span></swiper-slide>
                                            <swiper-slide><span @click="reminderTab = 'admin'" :class="{ active: reminderTab === 'admin' }">Admin Notiication <label class="tag" :style="`background: #${form.reminder.has_notification ? '2f7eed' : 'f2a31d'}`">{{ form.reminder.has_notification ? 'On' : 'Off' }}</label></span></swiper-slide>
                                            <swiper-slide><span @click="reminderTab = 'award'" :class="{ active: reminderTab === 'award' }">Award Points <label class="tag" :style="`background: #${form.data_settings.rewards.has_reward_points ? '2f7eed' : 'f2a31d'}`">{{ form.data_settings.rewards.has_reward_points ? 'On' : 'Off' }}</label></span></swiper-slide>
                                        </swiper>
                                        <Transition duration="550" name="nested">
                                            <div class="outer" v-if="reminderTab === 'reminders'">
                                                <label for="track_reminders" class="switch_option capsule_btn mt-2">
                                                    <h5 class="large">Reminders</h5>
                                                    <input type="checkbox" id="track_reminders" :true-value="1" :false-value="0" v-model="form.reminder.has_reminder" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <Transition duration="550" name="nested">
                                                    <div v-if="form.reminder.has_reminder" class="mt-2 outer">
                                                        <ul class="tab_sec mb-4">
                                                            <li @click="clientReminderTab = 'goal'" :class="{ active: clientReminderTab === 'goal' }">Goal Setting Message</li>
                                                            <li @click="clientReminderTab = 'client'" :class="{ active: clientReminderTab === 'client' }">Client Reminders</li>
                                                        </ul>
                                                        <div v-if="clientReminderTab === 'goal'">
                                                            <div class="edit_section mb-2">
                                                                <sending-method v-model="form.reminder.goal_sending_method" />
                                                            </div>
                                                            <div v-if="form.reminder.goal_sending_method == 1 || form.reminder.goal_sending_method == 3">
                                                                <email-component v-model="goalEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleGoalDefaultEmail" preview-module="progress-tracking" ref="goal-email-component" is-full-view />
                                                            </div>
                                                            <div v-if="form.reminder.goal_sending_method == 2 || form.reminder.goal_sending_method == 3" class="sms-component">
                                                                <sms-component v-model="form.reminder.goal_sms_message" media-field-name="goal_sms_media" :media="form.reminder.goal_media" :default-sms-handler="true" preview-module="progress-tracking" :handle-default-sms="handleGoalDefaultSms" :update-sms-text="updateSmsText()" ref="goal-sms-component" />
                                                            </div>
                                                        </div>
                                                        <div v-if="clientReminderTab === 'client'">
                                                            <div class="edit_section mb-2">
                                                                <sending-method v-model="form.reminder.sending_method" />
                                                            </div>
                                                            <div v-if="form.reminder.sending_method == 1 || form.reminder.sending_method == 3">
                                                                <email-component v-model="reminderEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleReminderDefaultEmail" preview-module="progress-tracking" ref="reminder-email-component" is-full-view />
                                                            </div>
                                                            <div v-if="form.reminder.sending_method == 2 || form.reminder.sending_method == 3" class="sms-component">
                                                                <sms-component v-model="form.reminder.sms_message" media-field-name="reminder_sms_media" :media="form.reminder.media" :default-sms-handler="true" preview-module="progress-tracking" :handle-default-sms="handleReminderDefaultSms" :update-sms-text="updateSmsText()" ref="message-sms-component" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </Transition>
                                        <Transition duration="550" name="nested">
                                            <div class="outer" v-if="reminderTab === 'admin'">
                                                <label for="adm_notification" class="switch_option capsule_btn mt-2">
                                                    <h5 class="large">Admin Notification</h5>
                                                    <input type="checkbox" id="adm_notification" :true-value="1" :false-value="0" v-model="form.reminder.has_notification" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <Transition duration="550" name="nested">
                                                    <div v-if="form.reminder.has_notification" class="mt-2 outer">
                                                        <div class="edit_section mb-2">
                                                            <sending-method v-model="form.reminder.notification_sending_method" />
                                                        </div>
                                                        <div v-if="form.reminder.notification_sending_method == 1 || form.reminder.notification_sending_method == 3">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Email Recipient</label>
                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.notification_email_recipient }">
                                                                        <Field autocomplete="off" name="notification_email_recipient" v-model="form.reminder.notification_email_recipient" rules="required" label="email recipient">
                                                                            <vue-tags-input :tags="form.reminder.notification_email_recipient" @on-tags-changed="handleNotificationEmailRecipient" :add-tag-on-blur="true" />
                                                                        </Field>
                                                                    </div>
                                                                    <ErrorMessage name="notification_email_recipient" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <email-component v-model="notificationEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleNotificationDefaultEmail" preview-module="progress-tracking" ref="notification-email-component" is-full-view />
                                                        </div>
                                                        <div v-if="form.reminder.notification_sending_method == 2 || form.reminder.notification_sending_method == 3" class="sms-component">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">SMS Recipient</label>
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.notification_sms_recipient }">
                                                                    <Field autocomplete="off" name="notification_sms_recipient" v-model="form.reminder.notification_sms_recipient" rules="required" label="sms recipient">
                                                                        <vue-tags-input :tags="form.reminder.notification_sms_recipient" @on-tags-changed="handleNotificationSMSRecipient" :add-tag-on-blur="true" />
                                                                    </Field>
                                                                </div>
                                                                <ErrorMessage name="notification_sms_recipient" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <sms-component v-model="form.reminder.notification_sms_message" media-field-name="notification_sms_media" :media="form.reminder.notification_media" :default-sms-handler="true" preview-module="progress-tracking" :handle-default-sms="handleNotificationDefaultSms" :update-sms-text="updateSmsText()" ref="notification-sms-component" />
                                                    </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </Transition>
                                        <Transition duration="550" name="nested">
                                            <div class="outer" v-if="reminderTab === 'award'">
                                                <label for="award_point" class="switch_option capsule_btn mt-2">
                                                    <h5 class="large">Award Points</h5>
                                                    <input type="checkbox" id="award_point" :true-value="1" :false-value="0" v-model="form.data_settings.rewards.has_reward_points" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <Transition duration="550" name="nested">
                                                    <div v-if="form.data_settings.rewards.has_reward_points">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Points Awarded</label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="off" type="number" name="award_point" v-model="form.data_settings.rewards.reward_points" placeholder="1" label="award point" :class="{ 'has-error': errors.award_point }" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <label for="reward_notify" class="switch_option capsule_btn m-0">
                                                            <h5 class="large">Subscriber Reward Notifications?</h5>
                                                            <input type="checkbox" id="reward_notify" v-model="form.data_settings.rewards.reward_notif" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                        <div v-if="form.data_settings.rewards.reward_notif">
                                                            <div class="edit_section">
                                                                <sending-method v-model="form.data_settings.rewards.reward_notif_method" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mt-2">
                                                            <div class="group_item">
                                                                <label class="input_label">You just earned {{ form.data_settings.rewards.reward_points }} points for</label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="off" type="text" name="award_reason" v-model="form.data_settings.rewards.reward_reason" placeholder="Type here..." label="award reason" :class="{ 'has-error': errors.award_reason }" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="workoutStep == 5">
                            <div class="section_content w-100 open_area" @click="goTo('seosection')" ref="seosetting">
                                <div class="section_header">
                                    <h2>SEO Settings</h2>
                                    <!-- <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button> -->
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_title }">
                                                    <Field autocomplete="off" type="text" name="seo_title" placeholder="ex: The title will be displayed in browser tabs." v-model="form.public_settings.seo_title" />
                                                </div>
                                                <ErrorMessage name="seo_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Keywords</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_keyword }">
                                                    <Field autocomplete="off" type="text" name="seo_keyword" placeholder="ex: Several keywords that describe your page best." v-model="form.public_settings.seo_keyword" />
                                                </div>
                                                <ErrorMessage name="seo_keyword" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.seo_desc }">
                                                    <Field autocomplete="off" type="textarea" name="seo_desc" v-model="form.seo_desc">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.public_settings.seo_desc"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="seo_desc" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <label for="page_indexed" class="switch_option capsule_btn border-0">
                                        <h5 class="large">I want my page indexed by search engine</h5>
                                        <input type="checkbox" id="page_indexed" :true-value="1" :false-value="0" v-model="form.public_settings.seo_index" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="section_content w-100 open_area" @click="goTo('sharesection')" ref="sharesetting">
                                <div class="section_header">
                                    <h2>Social Share</h2>
                                    <!-- <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button> -->
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_share_title }">
                                                    <Field autocomplete="off" type="text" name="seo_share_title" placeholder="ex: New Title" v-model="form.public_settings.seo_share_title" />
                                                </div>
                                                <ErrorMessage name="seo_share_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.seo_share_desc }">
                                                    <Field autocomplete="off" type="textarea" name="seo_share_desc" v-model="form.public_settings.seo_share_desc">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.public_settings.seo_share_desc"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="seo_share_desc" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="sub_header mt-4">SEO Thumbnail</h3>
                                    <image-library v-model="form.public_settings.seo_thumb" image-type="social-share" />
                                </div>
                            </div>
                            <div class="section_content w-100 open_area" @click="goTo('favsection')" ref="favsetting">
                                <div class="section_header">
                                    <h2>Favicon</h2>
                                    <!-- <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button> -->
                                </div>
                                <div class="color_container mt-2">
                                    <image-library v-model="form.public_settings.seo_fav" image-type="favicon" />
                                </div>
                            </div>
                        </div>
                        <button type="button" class="preview_btn" @click="preview = true">Preview</button>
                    </div>            
                    <div class="action_wpr">
                        <button type="button" class="btn cancel_btn" @click="close" v-if="workoutStep == 1">Close</button>
                        <button type="button" class="btn cancel_btn" @click="workoutStep -= 1" v-if="workoutStep > 1">Back</button>
                        <button type="button" class="btn danger_btn" v-if="isEdit">Delete</button>
                        <button type="button" class="btn save_btn" @click="workoutStep += 1" v-if="workoutStep < 5">Next</button>
                        <button type="button" class="btn save_btn" v-if="workoutStep == 5">Finish</button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="workoutStep == 1">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <div class="px-3">
                            <v-calendar :first-day-of-week="2" :attributes="attributes" is-expanded v-if="form.public_settings.workout_type == 'recurring'">
                                <template #day-popover="{dayTitle }">
                                    <div class="task_details">
                                        <div class="task_header">
                                            {{ dayTitle }}
                                            <h6><i class="fas fa-clock mr-1"></i>{{`${hours.value[0]} ${clock.value[0]}`}}</h6>
                                        </div>
                                        <popover-row
                                            v-for="attr in attributes"
                                            :key="attr.key"
                                            :attribute="attr">
                                            <div class="upcoming_events">
                                                <h5>Assigned Habits</h5>
                                                <ul>
                                                    <li>{{ attr.customData.description }}</li>
                                                    <li>Mauris eget blandit?</li>
                                                    <li>Morbi pharetra justo?</li>
                                                </ul>
                                            </div>
                                        </popover-row>
                                    </div>
                                </template>
                            </v-calendar>
                            <div class="hourly_info" v-if="form.public_settings.workout_type == 'one-time'">
                                Your client will be reminded every {{reminderInt}} hours that they need to submit their progress until 31st August, 2022
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="workoutStep == 2">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="block_library">
                    <div class="library_header">
                        <button type="button" class="pointer" @click="favorites = !favorites;"><i :class="favorites ? 'fas fa-heart' : 'far fa-heart'"></i></button>
                        <div class="filter_search">
                            <input type="text" name="search" placeholder="Search...">
                            <button type="button" class="search_btn">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                        <a @click="selectedFilterTags = []" v-if="selectedFilterTags.length">Clear FIlter</a>
                    </div>
                    <ul class="search_tags" v-if="selectedFilterTags.length">
                        <li v-for="(tag, t) in selectedFilterTags" :key="t" :class="tag.type">{{ tag.title }} <span class="delete_btn" @click="deleteFilterTag(t)"><i class="fas fa-times"></i></span></li>
                    </ul>
                    <div class="filters">
                        <div class="bulk_action" v-if="selectedLibraryItems.length">
                            <button class="filter_item"><i class="fas fa-plus m-0"></i>Add to Block</button>
                        </div>
                        <div class="filter_item" @click.self="type = !type" v-click-outside="closeSortingType">
                            Exercise Type <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="type ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) of exercise_type" :key="f" @click="selectFilterTag(filter.title, 'exercise')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="filter_item" @click.self="body_part = !body_part" v-click-outside="closeSortingBodyPart">
                            Body Part <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="body_part ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) in body_part_filters" :key="f" @click="selectFilterTag(filter.title, 'part')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="filter_item" @click.self="muscle_target = !muscle_target" v-click-outside="closeSortingMuscle">
                            Muscle Target <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="muscle_target ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) in muscle_target_filters" :key="f" @click="selectFilterTag(filter.title, 'muscle')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="filter_item" @click.self="equipment = !equipment" v-click-outside="closeSortingEquipment">
                            Equipments <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="equipment ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) in equipment_filters" :key="f" @click="selectFilterTag(filter.title, 'equipment')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="other_filter_items" @click="otherFilters = !otherFilters">
                            <i class="fas fa-ellipsis-v"></i>
                        </div>
                        <div class="attribute_bar" :class="{show : otherFilters}">
                            <h3>All Attributes <button @click="otherFilters = false;"><i class="fas fa-times"></i></button></h3>
                            <div class="toggle_btn">
                                <a v-if="allAttr" @click="openAllGrp" class="toggle_btn">Open All</a>
                                <a v-else @click="closeAllGrp" class="toggle_btn">Close All</a>
                            </div>
                            <div class="attribute_grp">
                                <h4 @click="accFilter($event)"><a class="pinned"><i class="fas fa-thumbtack"></i></a>Exercise Type <i class="fas fa-chevron-down"></i></h4>
                                <ul>
                                    <li v-for="(filter, f) of exercise_type" :key="`body-part-${f}`" @click="selectFilterTag(filter.title, 'exercise')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="attribute_grp">
                                <h4 @click="accFilter($event)"><a class="pinned"><i class="fas fa-thumbtack"></i></a>Body Part <i class="fas fa-chevron-down"></i></h4>
                                <ul>
                                    <li v-for="(filter, f) of body_part_filters" :key="`part-${f}`" @click="selectFilterTag(filter.title, 'part')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="attribute_grp">
                                <h4 @click="accFilter($event)"><a class="pinned"><i class="fas fa-thumbtack"></i></a>Muscle Target <i class="fas fa-chevron-down"></i></h4>
                                <ul>
                                    <li v-for="(filter, f) of muscle_target_filters" :key="`muscle-${f}`" @click="selectFilterTag(filter.title, 'muscle')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="attribute_grp">
                                <h4 @click="accFilter($event)"><a class="pinned"><i class="fas fa-thumbtack"></i></a>Synergist <i class="fas fa-chevron-down"></i></h4>
                                <ul>
                                    <li v-for="(filter, f) of synergist_filters" :key="`muscle-${f}`" @click="selectFilterTag(filter.title, 'muscle')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="attribute_grp">
                                <h4 @click="accFilter($event)"><a class="pinned"><i class="fas fa-thumbtack"></i></a>Equipment <i class="fas fa-chevron-down"></i></h4>
                                <ul>
                                    <li v-for="(filter, f) of equipment_filters" :key="`equipment-${f}`" @click="selectFilterTag(filter.title, 'equipment')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="attribute_grp">
                                <h4 @click="accFilter($event)"><a><i class="fas fa-thumbtack"></i></a>Skeletal Action <i class="fas fa-chevron-down"></i></h4>
                                <ul>
                                    <li v-for="(filter, f) of skeletal_action" :key="`equipment-${f}`" @click="selectFilterTag(filter.title, 'skeletal')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="attribute_grp">
                                <h4 @click="accFilter($event)"><a><i class="fas fa-thumbtack"></i></a>Movement <i class="fas fa-chevron-down"></i></h4>
                                <ul>
                                    <li v-for="(filter, f) of movement_filters" :key="`body-part-${f}`" @click="selectFilterTag(filter.title, 'movement')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="attribute_grp">
                                <h4 @click="accFilter($event)"><a><i class="fas fa-thumbtack"></i></a>Body Position <i class="fas fa-chevron-down"></i></h4>
                                <ul>
                                    <li v-for="(filter, f) of body_position" :key="`body-part-${f}`" @click="selectFilterTag(filter.title, 'position')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="attribute_grp">
                                <h4 @click="accFilter($event)"><a><i class="fas fa-thumbtack"></i></a>Contraction <i class="fas fa-chevron-down"></i></h4>
                                <ul>
                                    <li v-for="(filter, f) of contraction_filters" :key="`muscle-${f}`" @click="selectFilterTag(filter.title, 'contraction')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="attribute_grp">
                                <h4 @click="accFilter($event)"><a><i class="fas fa-thumbtack"></i></a>Difficulty <i class="fas fa-chevron-down"></i></h4>
                                <ul>
                                    <li v-for="(filter, f) of difficulty_filters" :key="`muscle-${f}`" @click="selectFilterTag(filter.title, 'difficulty')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="attribute_grp">
                                <h4 @click="accFilter($event)"><a><i class="fas fa-thumbtack"></i></a>Avatar Gender <i class="fas fa-chevron-down"></i></h4>
                                <ul>
                                    <li v-for="(filter, f) of avatar_gender" :key="`muscle-${f}`" @click="selectFilterTag(filter.title, 'avatar')">
                                        <label class="checkbox">
                                            <span :class="{'active' : selectedFilterTags.some(att => att.title === filter.title)}"><i class="fas fa-check"></i></span>
                                            {{ filter.title }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="attribute_grp" v-if="list_tab == 'my_ex'">
                                <h4 @click="list_tab = 'thrive'"><a><i class="fas fa-thumbtack"></i></a>Thrive Excercises</h4>
                            </div>
                            <div class="attribute_grp" v-if="list_tab == 'thrive'">
                                <h4 @click="list_tab = 'my_ex'"><a><i class="fas fa-thumbtack"></i></a>My Excercises</h4>
                            </div>
                            <!-- <button type="button" class="add_attribute" @click="list_tab = 'thrive'" >Thrive Excercises</button>
                            <button type="button" class="add_attribute" @click="list_tab = 'my_ex'" >My Excercises</button> -->
                        </div>
                    </div>
                    <ul class="list" :class="{'target' : showContent}" v-if="favorites">
                        <li v-for="(item, i) in favoriteList" :key="i" :class="{ selected : selectedLibraryItems.includes(`item-${i}`) }">
                            <div class="action_items">
                                <label :for="`library-item-${i}`" class="checkbox">
                                    <input type="checkbox" :id="`library-item-${i}`" :value="`item-${i}`" v-model="selectedLibraryItems" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                </label>
                                <a @click="addToBlock(item)"><i class="fas fa-plus"></i>Add to block</a>
                            </div>
                            <div class="video_wpr">
                                <div class="video-overlay" @click="show_video = true;"></div>
                                <iframe :src="item.link" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                            </div>
                            <h6>{{ item.name }}</h6>
                        </li>
                    </ul>
                    <ul class="list" :class="{'target' : showContent}" v-if="list_tab == 'my_ex' && !favorites">
                        <li v-for="(item, i) in my_exercises" :key="i" :class="{ selected : selectedLibraryItems.includes(`item-${i}`) }">
                            <div class="action_items">
                                <label :for="`library-item-${i}`" class="checkbox">
                                    <input type="checkbox" :id="`library-item-${i}`" :value="`item-${i}`" v-model="selectedLibraryItems" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                </label>
                                <a @click="addToBlock(item)"><i class="fas fa-plus"></i>Add to block</a>
                            </div>
                            <div class="video_wpr">
                                <div class="video-overlay" @click="show_video = true;"></div>
                                <iframe :src="item.link" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                            </div>
                            <h6>{{ item.name }}</h6>
                        </li>
                    </ul>
                    <ul class="list" :class="{'target' : showContent}" v-if="list_tab == 'thrive' && !favorites">
                        <li v-for="(item, i) in blockLibrary" :key="i" :class="{ selected : selectedLibraryItems.includes(`item-${i}`) }">
                            <div class="action_items">
                                <label :for="`library-item-${i}`" class="checkbox">
                                    <input type="checkbox" :id="`library-item-${i}`" :value="`item-${i}`" v-model="selectedLibraryItems" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                </label>
                                <a @click="addToBlock(item)"><i class="fas fa-plus"></i>Add to block</a>
                            </div>
                            <div class="video_wpr">
                                <div class="video-overlay" @click="show_video = true;"></div>
                                <iframe :src="item.link" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                            </div>
                            <h6>{{ item.name }}</h6>
                        </li>
                    </ul>
                    <div class="load_more">
                        <img src="@/assets/images/load_more.gif">Loading
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="workoutStep == 3">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="full_preview" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="large">Full View</h5>
                            <input type="checkbox" id="full_preview" v-model="fullPreview" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area workout_scroll">
                        <PublicPage :clr="{accent_clr : form.public_settings.element_color, timer_clr : form.public_settings.timer_color, btn_clr : form.public_settings.submit_btn_bgcolor, btn_txt_clr : form.public_settings.submit_btn_textcolor, block_bg_clr: form.public_settings.block_bg_color}" />
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="workoutStep == 4">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <ul class="alt_prev">
                    <li @click="notificType = 'email'" :class="{'active' : notificType == 'email'}">E-mail</li>
                    <li @click="notificType = 'sms'" :class="{'active' : notificType == 'sms'}">SMS</li>
                </ul>
                <div class="cell">
                    <div class="content_area" v-if="notificType == 'email'">
                        <div class="msg_preview">
                            <h2>Progress Tracking Reminder</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Onboard Me</h5>
                                    <h6><span>To:</span>Robert Bacins</h6>
                                </div>
                            </div>
                            <p>
                                <!-- <span>Content:</span> -->
                                Eureka Wendy!<br/><br/>
                                A new client dashboard has been created for you by Johnny dev.
                                In your OnboardMe client area, you can access the resources you need to succeed!
                                Inside you can learn, ask questions, and more!
                                If you ever get logged out don`t worry!
                                Here is the link to your client dashboard-<br/>
                                User ID:
                                Password:
                                Thanks for being awesome Wendy!
                                Team OnboardMe
                            </p>
                            <p>
                                <span>Agreement to copyright:</span>
                                By accessing this information you agree to all copyrights by OnboardMe to this intellectual property and agree not to duplicate, redistribute, or alter this information in any manner without written permission.
                            </p>
                        </div>
                        <div class="msgFooter text-center mt-2">
                            <p>⚡ Powered by DigitalFox</p>
                            <p>OnboardMe</p>
                            <p><a>Unsubscribe</a></p>
                        </div>
                    </div>
                    <div class="content_area" v-if="notificType == 'sms'">
                        <div class="sms_preview">
                            <p>
                                Eureka Robert!<br/><br/>
                                A new client dashboard has been created for you by Johnny dev.
                                In your OnboardMe client area, you can access the resources you need to succeed!
                                Inside you can learn, ask questions, and more!
                                If you ever get logged out don`t worry!
                                Here is the link to your client dashboard.<br/><br/>
                                Thanks for being awesome Robert!<br/>
                                Team OnboardMe
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal add_question" v-if="addQuestion" :class="{active : addQuestion}">
                <div class="modal_container">
                    <div class="modal_header border-bottom">
                        <h1>Progress Scenario <span>Add Question</span></h1>
                        <button class="close_btn left_out" @click="addQuestion = false"><i class="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                    <div class="modal_body">
                        <div class="setting_wpr">
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Question Type</label>
                                    <div class="field_wpr m-0">
                                        <Multiselect v-model="questionType.value" v-bind="questionType" placeholder="Select type.."></Multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Question Text</label>
                                    <div class="field_wpr m-0">
                                        <input type="text" placeholder="How many pushups did you complete in 1 minute?">
                                    </div>
                                </div>
                            </div>
                            <div v-if="questionType.value == 'Assessment'">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Question on goal setting form</label>
                                        <div class="field_wpr m-0">
                                            <textarea name="" id="" cols="30" rows="10" placeholder="How many pushups do you want to be able to do in a minute?"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Portal Label</label>
                                        <div class="field_wpr m-0">
                                            <input type="text" placeholder="Assessment title on report..">
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Assessment Unit</label>
                                        <div class="field_wpr m-0">
                                            <Multiselect v-model="assessmentUnit.value" v-bind="assessmentUnit" placeholder="Select type.."></Multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3" v-if="questionType.value == 'One Answer' || questionType.value == 'Multi Answer'">
                                <h3 class="sub_header">Answer/Choice</h3>
                                <ul class="mcq_list">
                                    <li>
                                        <input type="text" placeholder="Choice #1">
                                        <button type="button" class="danger"><i class="fas fa-trash-alt"></i></button>
                                    </li>
                                </ul>
                                <button type="button" class="add_btn ml-auto mt-2 mr-0"><i class="fas fa-plus"></i>Add Choice</button>
                            </div>
                            <label for="question_required" class="switch_option capsule_btn m-0">
                                <h5 class="large">Required?</h5>
                                <input type="checkbox" id="question_required" v-model="questRequired" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                    </div>
                    <div class="modal_footer">
                        <button type="button" class="btn cancel_btn" @click="addQuestion = false">Cancel</button>
                        <button type="button" class="btn save_btn">Save</button>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="workoutStep == 5">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="area_wpr">
                    <h3 class="sub_header" ref="seosection" @click="setting('seosetting')">Search Engine Listing</h3>
                    <div class="google_ranking">
                        <h6>About 5,29,000 results(0.36 seconds)</h6>
                        <h4>{{ form.public_settings.seo_title }}</h4>
                        <p>{{ form.public_settings.seo_desc }}</p>
                    </div>
                </div>
                <div class="area_wpr" ref="sharesection" @click="setting('sharesetting')">
                    <h3 class="sub_header">Social Share</h3>
                    <div class="seo_card">
                        <img :src="form.public_settings.seo_thumb ? form.public_settings.seo_thumb : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                        <div class="seo_info">
                            <h3>{{ form.public_settings.seo_share_title }}</h3>
                            <p>{{ form.public_settings.seo_share_desc }}</p>
                        </div>
                    </div>
                </div>
                <div class="area_wpr" ref="favsection" @click="setting('favsetting')">
                    <h3 class="sub_header">Favicon</h3>
                    <div class="browser_layout">
                        <ul class="browser_tabs">
                            <li>
                                <img :src="form.public_settings.seo_fav ? form.public_settings.seo_fav : require('@/assets/images/logo.png')" alt="">
                                <h6>{{ form.public_settings.seo_title ? form.public_settings.seo_title : 'Thrive Coach' }}</h6>
                                <i class="fas fa-times"></i>
                            </li>
                            <li><i class="fas fa-plus"></i></li>
                        </ul>
                        <div class="browser_info">
                            <i class="fas fa-arrow-left"></i>
                            <i class="fas fa-arrow-right"></i>
                            <i class="fas fa-redo"></i>
                            <div class="browser_search">
                                <i class="fas fa-lock"></i>
                                <p>https://thrivecoach.io</p>
                                <i class="far fa-star ml-auto"></i>
                            </div>
                            <img src="@/assets/images/default-avatar.svg" class="user" alt=""/>
                            <i class="fas fa-ellipsis-v"></i>
                        </div>
                    </div>
                </div>
            </div>
            <PublicPagePreview v-model="fullPreview" :clr="{accent_clr : form.public_settings.element_color, timer_clr : form.public_settings.timer_color, btn_clr : form.public_settings.submit_btn_bgcolor, btn_txt_clr : form.public_settings.submit_btn_textcolor, block_bg_clr: form.public_settings.block_bg_color}" />
            <div class="modal secondary video_player" v-if="show_video">
                <div class="modal_container">
                    <div class="modal_header">
                        <button class="close_btn" @click="show_video = false"><i class="fas fa-times"></i></button>
                    </div>
                    <div class="video_wpr">
                        <iframe src="https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <div class="modal" v-if="smart_block_setup" :class="{active : smart_block_setup}">
                <div class="modal_container">
                    <div class="modal_header border-bottom">
                        <div class="modal_info">
                            <h1>Smart Block <span>Add Smart Block</span></h1>
                        </div>
                        <button class="close_btn left_out" @click="smart_block_setup = false"><i class="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                    <div class="modal_body">
                        <div class="smart_blocklist">
                            <ul>
                                <li v-for="(block, b) in smartBlocks" :key="b">
                                    <h3 class="sub_header">
                                        {{ block.block_name }}
                                        <button type="button" class="add_btn pointer ml-auto" @click="addSmartBlock(block)"><i class="fas fa-plus"></i>Add</button>
                                    </h3>
                                    <ul class="block_info">
                                        <li v-for="(item, i) in block.items" :key="i">
                                            <h6>{{ item.name }}</h6>
                                            <div class="video_wpr">
                                                <div class="video-overlay" @click="show_video = true;"></div>
                                                <iframe :src="item.link" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_area show_sidebar workout_config" v-if="editWorkout">
                <div class="global_setting">
                    <button class="close_btn" @click="editWorkout = false;"><i class="fas fa-long-arrow-alt-left"></i></button>
                    <div class="tabs_content">
                        <div class="section_container">
                            <h2 class="section_title">Edit Exercise {{ exercise.sets }}</h2>
                            <div class="content_panel" :class="exercise.circuit ? 'circuit' : 'progression', {'no_superset' : blockList[selectBlockPanel].items.length == 1}">
                                <div class="edit_content">
                                    <div class="thumb_section">
                                        <div class="video_section">
                                            <div class="video_wpr">
                                                <iframe :src="edit_thumb" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div>
                                            <h4 class="thumb_title">{{edit_thumb_ttl}} <span class="v_tag" v-if="exercise.variable">V</span></h4>
                                            <h6 class="exercise_info">
                                                {{ exercise.sets }} x <span v-if="measures.value !== 'Using Time'">{{ exercise.reps }} Reps</span><span v-if="measures.value == 'Using Time'">{{ exercise.time }} {{ exercise.timeUnit.value }}</span> | 
                                                <span v-if="exercise.has_rest"><a v-if="exercise.rest_min">{{ exercise.rest_min }} Min</a> <a v-if="exercise.rest_sec">{{ exercise.rest_sec }} Sec Rest</a></span>
                                                <span v-else>No Rest</span>
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="setting_wpr">
                                        <div class="form_grp p-0 mb-4">
                                            <div class="group_item">
                                                <label class="input_label">Measure</label>
                                                <div class="field_wpr">
                                                    <Multiselect v-model="measures.value" v-bind="measures"></Multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp p-0">
                                            <div class="group_item">
                                                <label class="input_label switch_option capsule_btn p-0 mb-2">
                                                    Tempo
                                                    <input type="checkbox" id="display_header" :true-value="1" :false-value="0" v-model="has_tempo" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="field_wpr" v-if="has_tempo">
                                                    <input type="text" v-model="tempo">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="setting_wpr">
                                    <div class="form_grp set_wpr">
                                        <div class="public_name">
                                            <label class="input_label">Public Name</label>
                                            <div class="field_wpr">
                                                <input type="text" v-model="edit_thumb_ttl">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp set_wpr">
                                        <div class="public_descriptions">
                                            <label class="input_label">Exercise Description </label>
                                            <div class="field_wpr">
                                                <textarea name="" id="" cols="30" rows="10" placeholder="Description goes here.."></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp set_wpr">
                                        <div class="single_item sets" :class="{disable : measures.value == 'Superset'}">
                                            <label class="input_label">Sets</label>
                                            <label class="fld_label">Number</label>
                                            <div class="field_wpr">
                                                <input type="number" min="0" v-model="exercise.sets">
                                            </div>
                                        </div>
                                        <div class="single_item" v-if="measures.value !== 'Using Time'">
                                            <label class="input_label">Reps</label>
                                            <div>
                                                <label class="fld_label">Number</label>
                                                <div class="field_wpr" v-if="!exercise.failure">
                                                    <input type="number" min="0" v-model="exercise.reps">
                                                </div>
                                                <div class="field_wpr" v-if="exercise.failure">
                                                    <input type="text" placeholder="Max" disabled>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="item_wpr" v-if="measures.value == 'Using Time'">
                                            <label class="input_label">Time</label>
                                            <div class="column-2">
                                                <div class="item">
                                                    <label class="fld_label">Minutes</label>
                                                    <div class="field_wpr">
                                                        <input type="number" min="0" v-model="exercise.time_min">
                                                    </div>
                                                </div>
                                                <div class="item">
                                                    <label class="fld_label">Seconds</label>
                                                    <div class="field_wpr">
                                                        <input type="number" min="0" v-model="exercise.time_sec">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="item_wpr">
                                            <label class="input_label">Load</label>
                                            <div class="column-2">
                                                <div class="item" v-if="exercise.units.value !== 'BW' && !exercise.bodyweight">
                                                    <label class="fld_label">Number</label>
                                                    <div class="field_wpr">
                                                        <input type="number" min="0" v-model="exercise.load">
                                                    </div>
                                                </div>
                                                <div class="item" v-if="!exercise.bodyweight">
                                                    <label class="fld_label">Unit</label>
                                                    <div class="field_wpr">
                                                        <Multiselect v-model="exercise.units.value" v-bind="exercise.units"></Multiselect>
                                                    </div>
                                                </div>
                                                <div class="item" v-if="exercise.bodyweight">
                                                    <label class="fld_label">Unit</label>
                                                    <div class="field_wpr">
                                                        <Multiselect v-model="exercise.units.value" v-bind="exercise.units" disabled></Multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="item_wpr rest">
                                            <label class="input_label switch_option capsule_btn p-0 mb-2">
                                                Rest
                                                <input type="checkbox" id="display_header" :true-value="1" :false-value="0" v-model="exercise.has_rest" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div class="column-2" v-if="exercise.has_rest">
                                                <div class="item">
                                                    <label class="fld_label">Minutes</label>
                                                    <div class="field_wpr">
                                                        <input type="number" v-model="exercise.rest_min">
                                                    </div>
                                                </div>
                                                <div class="item">
                                                    <label class="fld_label">Seconds</label>
                                                    <div class="field_wpr">
                                                        <input type="number" v-model="exercise.rest_sec">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="var_list">
                                        <label class="switch_option capsule_btn m-0 border-0" for="variable" :class="{'disable' : exercise.circuit}">
                                            <h5>Variable</h5>
                                            <input type="checkbox" id="variable" :true-value="1" :false-value="0" v-model="exercise.variable" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div :class="{'blank_space' : measures.value == 'Using Time'}">
                                            <label class="switch_option capsule_btn m-0 border-0" for="failure" v-if="measures.value !== 'Using Time'">
                                                <h5>To Failure</h5>
                                                <input type="checkbox" id="failure" :true-value="1" :false-value="0" v-model="exercise.failure" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <label class="switch_option capsule_btn m-0 border-0" for="bodyweight">
                                            <h5>Bodyweight Only</h5>
                                            <input type="checkbox" id="bodyweight" :true-value="1" :false-value="0" v-model="exercise.bodyweight" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div class="var_table" v-if="exercise.variable">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Set</th>
                                                    <th v-if="measures.value !== 'Using Time'">Reps</th>
                                                    <th v-if="measures.value == 'Using Time'">Time</th>
                                                    <th v-if="has_tempo">Tempo</th>
                                                    <th>Load</th>
                                                    <th>Rest</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(set, s) in exercise.set_table" :key="s">
                                                    <td>{{s + 1}}</td>
                                                    <td v-if="measures.value !== 'Using Time'">
                                                        <div class="row">
                                                            <input type="number" v-model="set.reps" v-if="set.repUnit.value !== 'Max Reps'">
                                                            <div class="form_grp small p-0">
                                                                <div class="field_wpr">
                                                                    <Multiselect v-model="set.repUnit.value" v-bind="set.repUnit"></Multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td v-if="measures.value == 'Using Time'">
                                                        <div class="row">
                                                            <div>
                                                                <label class="fld_label">Min</label>
                                                                <input type="number" v-model="set.time_min">
                                                            </div>
                                                            <div>
                                                                <label class="fld_label">Sec</label>
                                                                <input type="number" v-model="set.time_sec">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td v-if="has_tempo">
                                                        <input type="number" v-model="tempo">
                                                    </td>
                                                    <td>
                                                        <div class="row">
                                                            <input type="number" v-model="set.load" v-if="set.units.value !== 'BW'">
                                                            <div class="form_grp small p-0">
                                                                <div class="field_wpr">
                                                                    <Multiselect v-model="set.units.value" v-bind="set.units" disabled></Multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="row">
                                                            <div>
                                                                <label class="fld_label">Min</label>
                                                                <input type="number" v-model="set.rest_min">
                                                            </div>
                                                            <div>
                                                                <label class="fld_label">Sec</label>
                                                                <input type="number" v-model="set.rest_sec">
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="other_content">
                                        <div class="group_title">
                                            <h4>Attributes<span>(Client does not see this)</span></h4>
                                            <div class="attribute_list" v-if="exercise.selectedTags.length" @click="exercise.selectedTags = [];">Clear Attributes</div>
                                            <div class="attribute_list" @click="exercise.addAttributes = !exercise.addAttributes;">{{exercise.addAttributes ? 'Close' : 'Add'}} Attributes</div>
                                        </div>
                                        <ul class="att_tags">
                                            <li v-for="(tag, t) in exercise.selectedTags" :key="t" :class="tag.type">{{ tag.title }} <span class="delete_btn" @click="deleteAtt(t)"><i class="fas fa-times"></i></span></li>
                                        </ul>
                                        <div class="form_grp mt-4">
                                            <div class="group_item">
                                                <div class="group_title">
                                                    <h4>Instructions</h4>
                                                </div>
                                                <div class="field_wpr">
                                                    <textarea name="" id="" cols="30" rows="10"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="action_wpr mt-5">
                                <label for="save_default" class="switch_option capsule_btn p-0 m-0 border-0" :class="{'disable' : exercise.circuit}">
                                    <h5 class="large mb-0">Save As Default</h5>
                                    <input type="checkbox" id="save_default" v-model="saveDefault" hidden>
                                    <div><span></span></div>
                                </label>
                                <label for="track_exercise" class="switch_option capsule_btn p-0 ml-3 mr-auto border-0">
                                    <h5 class="large mb-0">Track Exercise</h5>
                                    <input type="checkbox" id="track_exercise" v-model="track_exercise" hidden>
                                    <div><span></span></div>
                                </label>
                                <button class="btn cancel_btn" @click="editWorkout = false;">Cancel</button>
                                <button class="btn save_btn">Update</button>
                            </div>
                        </div>
                    </div>
                    <div class="attribute_bar" :class="{show : exercise.addAttributes}">
                        <h3>Attribute List <button @click="exercise.addAttributes = false;"><i class="fas fa-times"></i></button></h3>
                        <div class="toggle_btn">
                            <a v-if="allAttr" @click="openAllGrp" class="toggle_btn">Open All</a>
                            <a v-else @click="closeAllGrp" class="toggle_btn">Close All</a>
                        </div>
                        <div class="attribute_grp">
                            <h4 @click="accFunc($event)">Exercise Type <i class="fas fa-chevron-down"></i></h4>
                            <ul>
                                <li v-for="(tag, t) of exercise_type" :key="t" @click="selectTag(tag.title, 'exercise')">
                                    <label class="checkbox">
                                        <span :class="{'active' : exercise.selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                        {{ tag.title }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div class="attribute_grp">
                            <h4 @click="accFunc($event)">Body Part <i class="fas fa-chevron-down"></i></h4>
                            <ul>
                                <li v-for="(tag, t) of body_part_filters" :key="t" @click="selectTag(tag.title, 'part')">
                                    <label class="checkbox">
                                        <span :class="{'active' : exercise.selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                        {{ tag.title }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div class="attribute_grp">
                            <h4 @click="accFunc($event)">Muscle Target <i class="fas fa-chevron-down"></i></h4>
                            <ul>
                                <li v-for="(tag, t) of muscle_target_filters" :key="t" @click="selectTag(tag.title, 'muscle')">
                                    <label class="checkbox">
                                        <span :class="{'active' : exercise.selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                        {{ tag.title }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div class="attribute_grp">
                            <h4 @click="accFunc($event)">Synergist <i class="fas fa-chevron-down"></i></h4>
                            <ul>
                                <li v-for="(tag, t) of synergist_filters" :key="t" @click="selectTag(tag.title, 'synergist')">
                                    <label class="checkbox">
                                        <span :class="{'active' : exercise.selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                        {{ tag.title }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div class="attribute_grp">
                            <h4 @click="accFunc($event)">Equipment <i class="fas fa-chevron-down"></i></h4>
                            <ul>
                                <li v-for="(tag, t) of equipment_filters" :key="t" @click="selectTag(tag.title, 'equipment')">
                                    <label class="checkbox">
                                        <span :class="{'active' : exercise.selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                        {{ tag.title }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div class="attribute_grp">
                            <h4 @click="accFunc($event)">Skeletal Action<i class="fas fa-chevron-down"></i></h4>
                            <ul>
                                <li v-for="(tag, t) of skeletal_action" :key="t" @click="selectTag(tag.title, 'skeletal')">
                                    <label class="checkbox">
                                        <span :class="{'active' : exercise.selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                        {{ tag.title }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div class="attribute_grp">
                            <h4 @click="accFunc($event)">Movement <i class="fas fa-chevron-down"></i></h4>
                            <ul>
                                <li v-for="(tag, t) of movement_filters" :key="t" @click="selectTag(tag.title, 'movement')">
                                    <label class="checkbox">
                                        <span :class="{'active' : exercise.selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                        {{ tag.title }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div class="attribute_grp">
                            <h4 @click="accFunc($event)">Body Position <i class="fas fa-chevron-down"></i></h4>
                            <ul>
                                <li v-for="(tag, t) of body_position" :key="t" @click="selectTag(tag.title, 'position')">
                                    <label class="checkbox">
                                        <span :class="{'active' : exercise.selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                        {{ tag.title }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div class="attribute_grp">
                            <h4 @click="accFunc($event)">Contraction <i class="fas fa-chevron-down"></i></h4>
                            <ul>
                                <li v-for="(tag, t) of contraction_filters" :key="t" @click="selectTag(tag.title, 'contraction')">
                                    <label class="checkbox">
                                        <span :class="{'active' : exercise.selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                        {{ tag.title }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div class="attribute_grp">
                            <h4 @click="accFunc($event)">Difficulty <i class="fas fa-chevron-down"></i></h4>
                            <ul>
                                <li v-for="(tag, t) of difficulty_filters" :key="t" @click="selectTag(tag.title, 'difficulty')">
                                    <label class="checkbox">
                                        <span :class="{'active' : exercise.selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                        {{ tag.title }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div class="attribute_grp">
                            <h4 @click="accFunc($event)">Avatar Gender <i class="fas fa-chevron-down"></i></h4>
                            <ul>
                                <li v-for="(tag, t) of avatar_gender" :key="t" @click="selectTag(tag.title, 'avatar')">
                                    <label class="checkbox">
                                        <span :class="{'active' : exercise.selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                        {{ tag.title }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <button type="button" class="add_attribute" @click="newAttribute = true;"><i class="fas fa-plus"></i>Add Attribute</button>
                    </div>
                </div>
            </div>
            <div class="modal secondary" v-if="newAttribute" :class="{active : newAttribute}">
                <div class="modal_container">
                    <h2>Add New Attribute</h2>
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select Category</label>
                                <div class="field_wpr">
                                    <Multiselect v-model="selectCategory" :options="['Exercise Type', 'Body Part', 'Muscle Target', 'Equipment', 'Skeletal Action', 'Movement', 'Body Position', 'Contraction', 'Difficulty' ]"></Multiselect>
                                </div>
                            </div>
                            <div class="group_item">
                                <label class="input_label">Attribute Name</label>
                                <div class="field_wpr">
                                    <input type="text" placeholder="ex: New Attribute" v-model="new_attr">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="action_wpr mt-4">
                        <button class="btn cancel_btn mx-1" @click="newAttribute = false">Cancel</button>
                        <button class="btn save_btn mx-1" @click="addAttrFunc">Add</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal secondary" v-if="confirmation">
            <div class="modal_container">
                <img src="@/assets/images/alert.svg" alt="" class="alert_img">
                <h2>Are You Sure?</h2>
                <h5>If you select recurring workout this can not be added to a plan and must be performed as a recurring stand-alone workout. You can duplicate your recurring workout and change it to "one-time" if you want to add it to a plan in the future.</h5>
                <div class="action_wpr mt-4">
                    <button class="btn cancel_btn mx-1" @click="form.public_settings.workout_type = 'one-time';">Cancel</button>
                    <button class="btn save_btn mx-1" @click="confirmation = false;">Okay</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Draggable from 'vuedraggable';
import ImageLibrary from '@/components/image-library/ImageLibrary';
import PublicPage from './PublicPage.vue';
import PublicPagePreview from './PreviewPage.vue';
import Multiselect from '@vueform/multiselect';
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
SwiperCore.use([Navigation])

import { Field, Form, ErrorMessage } from 'vee-validate';
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'Wizard',
    components:{
        Multiselect,
        Swiper,
        SwiperSlide,
        Draggable,
        ImageLibrary,
        PublicPage,
        PublicPagePreview,
        Field,
        Form,
        ErrorMessage,
    },
    props:{
        modelValue: Boolean,
    },
    emits: ['update:modelValue'],
    data(){
        const todos = [
            {
                description: 'Pellentesque venenatis?',
                isComplete: false,
                dates: { weekdays: [3, 5, 7] },
                color: '#2f7eed',
            },
        ];
        return{
            incId: todos.length,
            todos,
            notificType: 'email',
            preview: true,
            workoutStep: 1,
            confirmation: false,
            timeChose: 'daily',
            reminderInt: 1,
            untilDate: null,
            everyday: 'every',
            monthlyOpt: 'repeat',
            yearlyOpt: 'every',
            until: 'ongoing',
            tab: 'reminders',
            toggleAll: 1,
            collectedForm:{
                setGoal: true,
                clientAvaiibility: true,
                showSectionlabel: false,
                labelText: 'Progress Questions',
                weight: false,
                bodyFat: false,
                circumference: false,
                circumferenceItem:[
                    {
                        id: 1,
                        name: 'Neck',
                        status: true,
                    },
                    {
                        id: 2,
                        name: 'Shoulders',
                        status: true,
                    },
                    {
                        id: 3,
                        name: 'Biceps',
                        status: false,
                    },
                    {
                        id: 4,
                        name: 'Chest',
                        status: false,
                    },
                    {
                        id: 5,
                        name: 'Belly Button',
                        status: false,
                    },
                    {
                        id: 6,
                        name: 'Waist',
                        status: false,
                    },
                    {
                        id: 7,
                        name: 'Hips',
                        status: false,
                    },
                    {
                        id: 8,
                        name: 'Thigh',
                        status: false,
                    },
                    {
                        id: 9,
                        name: 'Calf',
                        status: false,
                    },
                ],
                lenUnit: {
                    mode: 'single',
                    value: ['Inches'],
                    options: [
                        { value: 'Inches', label: 'Inches' },
                        { value: 'Centimeters', label: 'Centimeters' }
                    ],
                },
                unit: {
                    mode: 'single',
                    value: ['Pounds'],
                    options: [
                        { value: 'Pounds', label: 'Pounds' },
                        { value: 'Kilogram', label: 'Kilogram' }
                    ],
                },
                progressPic: false,
                customQuestions: false,
            },
            addQuestion: false,
            questionType: {
                mode: 'single',
                value: ['Comment'],
                options: [
                    { value: 'One Answer', label: 'Multiple Choice (Only One Answer)' },
                    { value: 'Multi Answer', label: 'Multiple Choice (Multiple Answer)' },
                    { value: 'Comment', label: 'Comment/Essay Box' },
                    { value: 'Bool', label: 'True/False' },
                    { value: 'Short', label: 'Short Answer' },
                    { value: 'Assessment', label: 'Assessment' },
                    { value: 'Upload', label: 'Upload' },
                ],
                searchable: true,
            },
            questRequired: true,
            fullPreview: false,
            headerTab: 'logo',
            display_cover: true,
            cover_type: 2,
            headlineSetting: false,
            subHeadlineSetting: false,
            headline_setting: {
                has_shadow: 0,
                shadow_x: 0,
                shadow_y: 4,
                shadow_blur: 4,
                shadow_color: 'rgba(0, 0, 0, 0.25)',
            },
            subheadline_setting: {
                has_shadow: 0,
                shadow_x: 0,
                shadow_y: 4,
                shadow_blur: 4,
                shadow_color: 'rgba(0, 0, 0, 0.68)',
            },
            has_cover_overlay: 1,
            overlay_color: '#000',
            overlay_opacity: 5,
            cover_background_color: '#2F7FED',
            footer_facebook: '',
            footer_twitter: '',
            footer_instagram: '',
            footer_company: '',
            footer_terms: '',
            footer_policy: '',
            footer_textcolor: '#2F7EED',
            hours: {
                mode: 'single',
                value: ['7'],
                options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                ],
                createTag: true
            },
            clock: {
                mode: 'single',
                value: ['AM'],
                options: [
                    { value: 'AM', label: 'AM' },
                    { value: 'PM', label: 'PM' }
                ],
                createTag: true
            },
            timezone:{
                mode: 'single',
                value: ['gmt'],
                options: [
                    { value: 'gmt', label: 'GMT +00:00 Africa/Accra' },
                    { value: 'cat', label: 'CAT +02:00 Africa/Blantyre' },
                    { value: 'cet', label: 'CET +01:00 Africa/Algiers' },
                    { value: 'eat', label: 'EAT +03:00 Africa/Addis_Ababa'},
                    { value: 'eet', label: 'EET +02:00 Africa/Cairo'},
                    { value: 'wat', label: 'WAT +01:00 Africa/Bangui'}
                ],
                searchable: true,
                createTag: true
            },
            weekDays: {
                mode: 'single',
                value: ['Mon'],
                options:[
                {
                    id: 1,
                    value: 'Mon',
                    label: 'Mon',
                    status: true,
                },
                {
                    id: 2,
                    value: 'Tue',
                    label: 'Tue',
                    status: true,
                },
                {
                    id: 3,
                    value: 'Wed',
                    label: 'Wed',
                    status: false,
                },
                {
                    id: 4,
                    value: 'Thu',
                    label: 'Thu',
                    status: false,
                },
                {
                    id: 5,
                    value: 'Fri',
                    label: 'Fri',
                    status: false,
                },
                {
                    id: 6,
                    value: 'Sat',
                    label: 'Sat',
                    status: false,
                },
                {
                    id: 7,
                    value: 'Sun',
                    label: 'Sun',
                    status: false,
                },
                ]
            },
            month: {
                mode: 'single',
                value: ['Jan'],
                options: [
                    { value: 'Jan', label: 'Jan' },
                    { value: 'Feb', label: 'Feb' },
                    { value: 'Mar', label: 'Mar' },
                    { value: 'Apr', label: 'Apr' },
                    { value: 'May', label: 'May' },
                    { value: 'Jun', label: 'Jun' },
                    { value: 'Jul', label: 'Jul' },
                    { value: 'Aug', label: 'Aug' },
                    { value: 'Sep', label: 'Sep' },
                    { value: 'Oct', label: 'Oct' },
                    { value: 'Nov', label: 'Nov' },
                    { value: 'Dec', label: 'Dec' },
                ],
            },
            photOrientation:{
                mode: 'single',
                value: ['Portrait'],
                options: [
                    { value: 'Portrait', label: 'Portrait' },
                    { value: 'Landscape', label: 'Landscape' }
                ]
            },
            photoView:[
                {
                    id: 1,
                    name: 'Front',
                    status: true,
                },
                {
                    id: 2,
                    name: 'Side',
                    status: true,
                },
                {
                    id: 3,
                    name: 'Back',
                    status: true,
                },
            ],
            custSection:[
                {
                    id: 1,
                    title: 'Section 1',
                    status: false,
                    label: false,
                },
                {
                    id: 2,
                    title: 'Section 2',
                    status: false,
                    label: false,
                },
            ],
            assessmentUnit:{
                mode: 'single',
                value: ['Seconds'],
                options: [
                    { value: 'Seconds', label: 'Seconds' },
                    { value: 'Minutes', label: 'Minutes' },
                    { value: 'Hours', label: 'Minutes' },
                    { value: 'Days', label: 'Days' },
                    { value: 'Months', label: 'Months' },
                    { value: 'Years', label: 'Years' },
                    { value: 'Millimeters', label: 'Millimeters' },
                    { value: 'Centimeters', label: 'Centimeters' },
                    { value: 'Inches', label: 'Inches' },
                    { value: 'Feet', label: 'Feet' },
                    { value: 'Yards', label: 'Yards' },
                    { value: 'Meters', label: 'Meters' },
                    { value: 'Milliliters', label: 'Milliliters' },
                    { value: 'CCs', label: 'CCs' },
                    { value: 'Ounces', label: 'Ounces' },
                    { value: 'Cups', label: 'Cups' },
                    { value: 'Quarts', label: 'Meters' },
                    { value: 'Pints', label: 'Pints' },
                    { value: 'Liters', label: 'Liters' },
                    { value: 'Gallons', label: 'Gallons' }
                ]
            },
            fontFamily:{
                mode: 'single',
                value: [],
                options:[]
            },
            fontWeight:{
                mode: 'single',
                value: [],
                options:[]
            },
            reminderTab: 'reminders',
            clientReminderTab: 'goal',
            form:{
                reminder: {
                    has_reminder: 1,
                    sending_method: 1,
                    throttle_for_user_tz: 1,
                    recipient_email:'',
                    recipient_mobile:'',
                    email_subject: '',
                    email_message: '',
                    sms_message: '',
                    media: [],
                    show_email_logo: true,
                    show_email_signature: false,
                    signature_id: 0,
                    logo_image: '',
                    goal_sending_method: 1,
                    goal_email_subject: '',
                    goal_email_message: '',
                    goal_sms_message: '',
                    show_goal_email_logo: true,
                    goal_logo_image: '',
                    goal_media: [],
                    has_notification: 0,
                    notification_sending_method: 1,
                    notification_email_subject: '',
                    notification_email_message: '',
                    notification_sms_message: '',
                    show_notification_email_logo: true,
                    notification_logo_image: '',
                    notification_media: [],
                    notification_email_recipient: [this.user ? this.user.email : ''],
                    notification_sms_recipient: [this.user ? this.user.sms_notification_number : ''],
                    show_goal_signature: 0,
                    goal_signature_id: 0,
                    show_signature: 0,
                    show_notification_signature: 0,
                    notification_signature_id: 0,
                    goal_email_button_bg_color: '',
                    goal_email_button_text_color: '',
                    email_button_bg_color: '',
                    email_button_text_color: '',
                    notification_email_button_bg_color: '',
                    notification_email_button_text_color: '',
                },
                public_settings: {
                    workout_type: 'one-time',
                    display_explainer_video: 0,
                    explainer_video_type: 0,
                    explainer_video: "",
                    explainer_embed: "",
                    display_company_branding: 1,
                    company_branding: "",
                    display_cover: 1,
                    headline: 'Time To Submit Your Workout',
                    subheadline: '',
                    display_logo:1,
                    logo: '',
                    display_author:1,
                    author:"",
                    display_header:1,
                    display_footer:1,
                    header_bgcolor: '#FFF',
                    header_textcolor: '#000',
                    submit_btn_text: 'Submit Workout',
                    element_color: '#2f7eed',
                    element_text_color: '#2C3E50',
                    block_bg_color: '#eff5ff',
                    timer_color: '#f2a31d',
                    submit_btn_textcolor: '#fff',
                    submit_btn_bgcolor: '#2f7eed',
                    footer_bgcolor: '#D4D4D4',
                    footer_textcolor: '#2C3E50',
                    cover_color: '#1976d2',
                    cover_textcolor: '#2C3E50',
                    cover: '',
                    small_cover: '',
                    footer_has_facebook: 1,
                    footer_has_twitter: 1,
                    footer_has_instagram: 1,
                    thankyou_bgcolor: '#edebeb',
                    thankyou_textcolor: '#2c3e50',
                    seo_title: 'Workout',
                    seo_keyword: 'Workout',
                    seo_desc: `Whatever can be measured can be improved and what you focus on becomes reality. Let's track your workout!`,
                    seo_index: 0,
                    seo_share_title: 'Workout',
                    seo_share_desc: `Whatever can be measured can be improved and what you focus on becomes reality. Let's track your workout!`,
                    seo_thumb: '',
                    seo_fav: '',
                },
                data_settings: {
                    has_weight: 1,
                    weight_unit: 'Pounds',
                    max_weight: 500,
                    has_body_fat: 1,
                    max_percent:100,
                    has_circumference: 0,
                    c_unit:'Inches',
                    has_progress_pics: 1,
                    photo_orientation:'Portrait',
                    photo_view:{
                        front: true,
                        back:true,
                        side: true
                    },
                    total_submission: 6,
                    limited_submission: 0,
                    reverse_goal: 1,
                    week_enabled: 1,
                    has_progress_question_label: 1,
                    progress_question_label: 'Progress Questions',
                    rewards: {
                        has_reward_points: 0,
                        reward_points: 0,
                        reward_notif: 0,
                        reward_notif_method: 1,
                        reward_reason: 'submitting your progress report',
                    },
                    neck: 1,
                    shoulders: 1,
                    chest: 1,
                    waist: 1,
                    biceps: 0,
                    belly_button: 0,
                    hips: 0,
                    thigh: 0,
                    calf: 0,
                },
            },
            goalEmail: {
                defaultHandler: true,
                subject: '',
                email: '',
                is_email_logo: 0,
                is_signature: 0,
                signature_id: 0,
                show_email_logo: 0,
                email_logo: '',
            },
            reminderEmail: {
                defaultHandler: true,
                subject: '',
                email: '',
                is_email_logo: 0,
                is_signature: 0,
                signature_id: 0,
                show_email_logo: 0,
                email_logo: '',
            },
            notificationEmail: {
                defaultHandler: true,
                subject: '',
                email: '',
                is_email_logo: 0,
                is_signature: 0,
                signature_id: 0,
                show_email_logo: 0,
                email_logo: '',
            },
            newBlock: false,
            blockList: [],
            is_edit: false,
            blockLibrary: [
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
                {
                    name: 'Dead Hang',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                },
                {
                    name: 'Thoracic Bridge',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                },
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
                {
                    name: 'Dead Hang',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                },
                {
                    name: 'Thoracic Bridge',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                },
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
                {
                    name: 'Dead Hang',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                },
                {
                    name: 'Thoracic Bridge',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                },
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
                {
                    name: 'Dead Hang',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                },
                {
                    name: 'Thoracic Bridge',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                },
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
                {
                    name: 'Dead Hang',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                },
                {
                    name: 'Thoracic Bridge',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                },
            ],
            activeRest: 0,
            list_tab: 'thrive',
            favorites: false,
            favoriteList: [
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
                {
                    name: 'Dead Hang',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                },
                {
                    name: 'Thoracic Bridge',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                },
            ],
            my_ex: false,
            my_exercises:[
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
                {
                    name: 'Dead Hang',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                },
                {
                    name: 'Thoracic Bridge',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                },
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
            ],
            selectedFilterTags:[],
            selectedLibraryItems: [],
            selectBlockPanel: 0,
            allAttr: 1,
            bulk_action: false,
            show_video: false,
            type: false,
            body_part: false,
            body_part_filters: [
                { title: 'Neck'},
                { title: 'Shoulders'},
                { title: 'Chest'},
                { title: 'Rotator Cuff'},
                { title: 'Traps'},
                { title: 'Upper Back'},
                { title: 'Middle Back'},
                { title: 'Lower Back'},
                { title: 'Biceps'},
                { title: 'Triceps'},
                { title: 'Forearms'},
                { title: 'Abs-Core'},
                { title: 'Abductors'},
                { title: 'Adductors'},
                { title: 'Glutes'},
                { title: 'Hamstrings'},
                { title: 'Quadriceps'},
                { title: 'Lower Legs'},
            ],
            muscle_target: false,
            muscle_target_filters: [
                { title: 'Adductor Brevis'},
                { title: 'Adductor Longus'},
                { title: 'Adductor Magnus'},
                { title: 'Biceps Brachii'},
                { title: 'Brachialis'},
                { title: 'Brachioradialis'},
                { title: 'Deep Rotators'},
                { title: 'Deltoid Anterior'},
                { title: 'Deltoid Lateral'},
                { title: 'Deltoid Posterior'},
                { title: 'Erector Spinae'},
                { title: 'Gastrocnemius'},
                { title: 'Gluteus Maximus'},
                { title: 'Gluteus Medius'},
                { title: 'Gluteus Minimus'},
                { title: 'Gracilis'},
                { title: 'Hamstrings'},
                { title: 'Iliopsoas'},
                { title: 'Infraspinatus'},
                { title: 'Latisimus Dorsi'},
                { title: 'Levator Scapula'},
                { title: 'Pectinuius'},
                { title: 'Pectoralis Major'},
                { title: 'Pectoralis Minor'},
                { title: 'Quadriceps'},
                { title: 'Rectus Abdominus'},
                { title: 'Sartorius'},
                { title: 'Soleus'},
                { title: 'Splenius'},
                { title: 'Sternocleidomastoid'},
                { title: 'Subscapularis'},
                { title: 'Tensor Fasciae Latae'},
                { title: 'Teres Major'},
                { title: 'Teres Minor'},
                { title: 'Tibialis Anterior'},
                { title: 'Transverse Abdominus'},
                { title: 'Trapezius Lower'},
                { title: 'Trapezius Middle'},
                { title: 'Trapezius Upper'},
                { title: 'Triceps Brachii'},
                { title: 'Wrist Extensors'},
                { title: 'Wrist Flexors'},
            ],
            equipment: false,
            equipment_filters: [
                { title: 'Ab Roller'},
                { title: 'Band'},
                { title: 'Barbell'},
                { title: 'Battle Rope'},
                { title: 'Body Weight'},
                { title: 'Balance Board'},
                { title: 'Balance Cushion'},
                { title: 'Bench'},
                { title: 'Bike'},
                { title: 'Bosu'},
                { title: 'Box'},
                { title: 'Cable-Single'},
                { title: 'Cable-Duel'},
                { title: 'Cones'},
                { title: 'Dip Stands'},
                { title: 'Dumbbells'},
                { title: 'EZ Bar'},
                { title: 'Foam Roller'},
                { title: 'Indian Club'},
                { title: 'Jacobs Ladder'},
                { title: 'Jump Rope'},
                { title: 'Kettlebell'},
                { title: 'Lacrosse Ball'},
                { title: 'Landmine'},
                { title: 'Leg Sled'},
                { title: 'Mace'},
                { title: 'Machine'},
                { title: 'Mat'},
                { title: 'Medicine Ball'},
                { title: 'Mini Band'},
                { title: 'Neutral Grip Bar'},
                { title: 'Parrallettes '},
                { title: 'Plate'},
                { title: 'Power Band'},
                { title: 'Pullup Bar'},
                { title: 'Push Sled'},
                { title: 'Rope Machine'},
                { title: 'Rower'},
                { title: 'Rebounder'},
                { title: 'Sand Bag'},
                { title: 'Scooter'},
                { title: 'Sliders'},
                { title: 'Smith Machine'},
                { title: 'Stability Ball'},
                { title: 'Suspension Trainer'},
                { title: 'Tire'},
                { title: 'Trap Bar'},
                { title: 'Versa Climber'},
            ],
            editWorkout: false,
            editRest: false,
            rest_min: 3,
            rest_sec: 0,
            measures:{
                mode: 'single',
                value: 'Using Reps',
                options: [
                    { value: 'Using Reps', label: 'Using Reps' },
                    { value: 'Using Time', label: 'Using Time' },
                    { value: 'Superset', label: 'Superset' }
                ]
            },
            otherFilters: false,
            exercise_type:[
                { title: 'Balance' },
                { title: 'Calisthenics' },
                { title: 'Cardio' },
                { title: 'Conditioning' },
                { title: 'Crossfit' },
                { title: 'Finisher' },
                { title: 'Mind-body' },
                { title: 'Olympic Lifting' },
                { title: 'Plyometric' },
                { title: 'Power' },
                { title: 'Powerlifting ' },
                { title: 'Prehab' },
                { title: 'Recovery' },
                { title: 'SAQ' },
                { title: 'Stabilization' },
                { title: 'Strength' },
                { title: 'Stretching' },
                { title: 'Warm-up' },
                { title: 'Yoga' },
            ],
            skeletal_action:[
                { title: 'Abduction' },
                { title: 'Adduction' },
                { title: 'Circumduction' },
                { title: 'Depression' },
                { title: 'Dorsiflexion' },
                { title: 'Elevation' },
                { title: 'Eversion' },
                { title: 'Extension' },
                { title: 'Flexion' },
                { title: 'Horizontal Abduction' },
                { title: 'Horizontal Adduction' },
                { title: 'Hyperextension' },
                { title: 'Inversion' },
                { title: 'Knee Valgus' },
                { title: 'Knee Varus' },
                { title: 'Knee Varus' },
                { title: 'Plantarflexion' },
                { title: 'Pronation' },
                { title: 'Protraction' },
                { title: 'Radial Deviation' }, 
                { title: 'Reposition' },
                { title: 'Retraction' },
                { title: 'Rotation' },
                { title: 'Scapular Depression' },
                { title: 'Scapular Elevation' },
                { title: 'Scapular Protraction' },
                { title: 'Scapular Retraction' },
                { title: 'Supination' },
                { title: 'Ulnar Deviation' },
                { title: 'Opposition' },
            ],
            movement_filters: [
                { title: 'Balancing'},
                { title: 'Bending'},
                { title: 'Bounding'},
                { title: 'Breathing'},
                { title: 'Carrying'},
                { title: 'Catching'},
                { title: 'Climbing'},
                { title: 'Crawling'},
                { title: 'Curling'},
                { title: 'Gripping'},
                { title: 'Hitting'},
                { title: 'Hopping'},
                { title: 'Jumping'},
                { title: 'Kicking'},
                { title: 'Leaping'},
                { title: 'Lifting'},
                { title: 'Lunging'},
                { title: 'Lying down'},
                { title: 'Pulling'},
                { title: 'Punching'},
                { title: 'Pushing'},
                { title: 'Reaching'},
                { title: 'Rolling'},
                { title: 'Rotating'},
                { title: 'Running'},
                { title: 'Sitting'},
                { title: 'Sprinting'},
                { title: 'Squatting'},
                { title: 'Standing'},
                { title: 'Stepping'},
                { title: 'Stretching'},
                { title: 'Throwing'},
                { title: 'Turning'},
                { title: 'Twisting'},
                { title: 'Walking'},
            ],
            body_position:[
                { title: 'Arched'},
                { title: 'Bent over'},
                { title: 'Bridged'},
                { title: 'Cross-legged'},
                { title: 'Fetal'},
                { title: 'Inclined'},
                { title: 'Half-Kneeling'},
                { title: 'Hinged'},
                { title: 'Inverted'},
                { title: 'Kneeling'},
                { title: 'Leaning'},
                { title: 'Plank'},
                { title: 'Prone'},
                { title: 'Quadruped'},
                { title: 'Reclined'},
                { title: 'Sidelined'},
                { title: 'Side-lying'},
                { title: 'Sitting'},
                { title: 'Single Leg'},
                { title: 'Single Arm'},
                { title: 'Squatting'},
                { title: 'Standing'},
                { title: 'Supine'},
                { title: 'Twisted'},
            ],
            contraction_filters:[
                { title: 'Concentric'},
                { title: 'Eccentric'},
                { title: 'Isometric'},
            ],
            difficulty_filters: [
                { title: 'Beginner'},
                { title: 'Intermediate'},
                { title: 'Advanced'},
            ],
            synergist_filters: [
                { title: 'Adductor Brevis'},
                { title: 'Adductor Longus'},
                { title: 'Adductor Magnus'},
                { title: 'Biceps Brachii'},
                { title: 'Brachialis'},
                { title: 'Brachioradialis'},
                { title: 'Deep Rotators'},
                { title: 'Deltoid Anterior'},
                { title: 'Deltoid Lateral'},
                { title: 'Deltoid Posterior'},
                { title: 'Erector Spinae'},
                { title: 'Gastrocnemius'},
                { title: 'Gluteus Maximus'},
                { title: 'Gluteus Medius'},
                { title: 'Gluteus Minimus'},
                { title: 'Gracilis'},
                { title: 'Hamstrings'},
                { title: 'Iliopsoas'},
                { title: 'Infraspinatus'},
                { title: 'Latisimus Dorsi'},
                { title: 'Levator Scapula'},
                { title: 'Pectinuius'},
                { title: 'Pectoralis Major'},
                { title: 'Pectoralis Minor'},
                { title: 'Quadriceps'},
                { title: 'Rectus Abdominus'},
                { title: 'Sartorius'},
                { title: 'Soleus'},
                { title: 'Splenius'},
                { title: 'Sternocleidomastoid'},
                { title: 'Subscapularis'},
                { title: 'Tensor Fasciae Latae'},
                { title: 'Teres Major'},
                { title: 'Teres Minor'},
                { title: 'Tibialis Anterior'},
                { title: 'Transverse Abdominus'},
                { title: 'Trapezius Lower'},
                { title: 'Trapezius Middle'},
                { title: 'Trapezius Upper'},
                { title: 'Triceps Brachii'},
                { title: 'Wrist Extensors'},
                { title: 'Wrist Flexors'},
            ],
            avatar_gender:[
                { title: 'Male'},
                { title: 'Female'},
            ],
            newAttribute: false,
            selectCategory: 'Exercise Type',
            new_attr: '',
            has_tempo: 1,
            tempo: '3131',
            circle_txt_color: '#121525',
            circle_bg_color: '#ffc4c4',
            exercise:{
                circuit: false,
                sets: 3,
                reps: 12,
                load: 135,
                units:{
                    mode: 'single',
                    value: 'LBS',
                    options: [
                        { label: 'Pounds', value: 'LBS' },
                        { label: 'Kilograms', value: 'KGS' },
                        { label: '% of 1RM', value: '1RM' },
                        { label: 'Bodyweight', value: 'BW' },
                        { label: 'Inches', value: 'IN' },
                        { label: 'Centimeters', value: 'CM' },
                        { label: 'Feet', value: 'FT' },
                        { label: 'Meters', value: 'ME' },
                        { label: 'Yards', value: 'YDS' },
                        { label: 'Miles', value: 'MI' },
                        { label: 'Kilometers', value: 'KM' },
                    ]
                },
                has_rest: 1,
                time_min: 0,
                time_sec: 30,
                timeUnit: {
                    mode: 'single',
                    value: 'Sec',
                    options: [
                        { value: 'Sec', label: 'Sec' },
                        { value: 'Min', label: 'Min' },
                    ]
                },
                rest_min: 0,
                rest_sec: 45,
                restTimeUnit: {
                    mode: 'single',
                    value: 'Sec',
                    options: [
                        { value: 'Sec', label: 'Sec' },
                        { value: 'Min', label: 'Min' },
                    ]
                },
                variable: 0,
                failure: 0,
                bodyweight: 0,
                selectOption: 'Variable',
                set_table:[
                    {
                        reps: 12,
                        time_min: 0,
                        time_sec: 30,
                        load: 135,
                        rest_min: 0,
                        rest_sec: 45,
                        repUnit:{
                            mode: 'single',
                            value: 'Reps',
                            options: [
                                { label: 'Reps', value: 'Reps' },
                                { label: 'Max Reps', value: 'Max Reps' },
                            ]
                        },
                        units:{
                            mode: 'single',
                            value: 'LBS',
                            options: [
                                { label: 'Pounds', value: 'LBS' },
                                { label: 'Kilograms', value: 'KGS' },
                                { label: '% of 1RM', value: '1RM' },
                                { label: 'Bodyweight', value: 'BW' },
                                { label: 'Inches', value: 'IN' },
                                { label: 'Centimeters', value: 'CM' },
                                { label: 'Feet', value: 'FT' },
                                { label: 'Meters', value: 'ME' },
                                { label: 'Yards', value: 'YDS' },
                                { label: 'Miles', value: 'MI' },
                                { label: 'Kilometers', value: 'KM' },
                            ]
                        },
                        timeUnit: {
                            mode: 'single',
                            value: 'Sec',
                            options: [
                                { value: 'Sec', label: 'Sec' },
                                { value: 'Min', label: 'Min' },
                            ]
                        },
                        restUnit: {
                            mode: 'single',
                            value: 'Sec',
                            options: [
                                { value: 'Sec', label: 'Sec' },
                                { value: 'Min', label: 'Min' },
                            ]
                        },
                    },
                    {
                        reps: 12,
                        time_min: 0,
                        time_sec: 30,
                        load: 135,
                        rest_min: 0,
                        rest_sec: 45,
                        repUnit:{
                            mode: 'single',
                            value: 'Reps',
                            options: [
                                { label: 'Reps', value: 'Reps' },
                                { label: 'Max Reps', value: 'Max Reps' },
                            ]
                        },
                        units:{
                            mode: 'single',
                            value: 'LBS',
                            options: [
                                { label: 'Pounds', value: 'LBS' },
                                { label: 'Kilograms', value: 'KGS' },
                                { label: '% of 1RM', value: '1RM' },
                                { label: 'Bodyweight', value: 'BW' },
                                { label: 'Inches', value: 'IN' },
                                { label: 'Centimeters', value: 'CM' },
                                { label: 'Feet', value: 'FT' },
                                { label: 'Meters', value: 'ME' },
                                { label: 'Yards', value: 'YDS' },
                                { label: 'Miles', value: 'MI' },
                                { label: 'Kilometers', value: 'KM' },
                            ]
                        },
                        timeUnit: {
                            mode: 'single',
                            value: 'Sec',
                            options: [
                                { value: 'Sec', label: 'Sec' },
                                { value: 'Min', label: 'Min' },
                            ]
                        },
                        restUnit: {
                            mode: 'single',
                            value: 'Sec',
                            options: [
                                { value: 'Sec', label: 'Sec' },
                                { value: 'Min', label: 'Min' },
                            ]
                        },
                    },
                    {
                        reps: 126,
                        time_min: 0,
                        time_sec: 30,
                        load: 135,
                        rest_min: 0,
                        rest_sec: 45,
                        repUnit:{
                            mode: 'single',
                            value: 'Reps',
                            options: [
                                { label: 'Reps', value: 'Reps' },
                                { label: 'Max Reps', value: 'Max Reps' },
                            ]
                        },
                        units:{
                            mode: 'single',
                            value: 'LBS',
                            options: [
                                { label: 'Pounds', value: 'LBS' },
                                { label: 'Kilograms', value: 'KGS' },
                                { label: '% of 1RM', value: '1RM' },
                                { label: 'Bodyweight', value: 'BW' },
                                { label: 'Inches', value: 'IN' },
                                { label: 'Centimeters', value: 'CM' },
                                { label: 'Feet', value: 'FT' },
                                { label: 'Meters', value: 'ME' },
                                { label: 'Yards', value: 'YDS' },
                                { label: 'Miles', value: 'MI' },
                                { label: 'Kilometers', value: 'KM' },
                            ]
                        },
                        timeUnit: {
                            mode: 'single',
                            value: 'Sec',
                            options: [
                                { value: 'Sec', label: 'Sec' },
                                { value: 'Min', label: 'Min' },
                            ]
                        },
                        restUnit: {
                            mode: 'single',
                            value: 'Sec',
                            options: [
                                { value: 'Sec', label: 'Sec' },
                                { value: 'Min', label: 'Min' },
                            ]
                        },
                    }
                ],
                addAttributes: false,
                selectedTags:[],
            },
            edit_thumb_ttl: '',
            edit_thumb: '',
            saveDefault: false,
            track_exercise: false,
            showContent: false,
            smart_block_setup: false,
            smartBlocks:[
                {
                    block_name: 'Smart Block 1',
                    block_type: 1,
                    smart_block: 0,
                    block_round: 3,
                    amrap: 0,
                    block_rest_min: 0,
                    block_rest_sec: 30,
                    has_duration: 0,
                    block_duration_min: 4,
                    block_duration_sec: 0,
                    blockDurationUnit: {
                        mode: 'single',
                        value: 'Sec',
                        options: [
                            { value: 'Sec', label: 'Sec' },
                            { value: 'Min', label: 'Min' },
                        ]
                    },
                    work_interval: 1,
                    rest_interval: 2,
                    block_interval: 1,
                    items:[
                        {
                            name: 'Seated Shoulder Press',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                        },
                        {
                            name: 'Dead Hang',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                        },
                        {
                            name: 'Thoracic Bridge',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                        },
                        {
                            name: 'Seated Shoulder Press',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                        },
                    ],
                }
            ],
        }
    },
    computed: {
        ...mapState({
            user: state => state.authModule.user,
            timezones: state => state.commonModule.timezones,
        }),
        ...mapGetters({
            replaceVariables: 'commonModule/REPLACE_VARIABLES',
            generateSignature: 'commonModule/GENERATE_SIGNATURE',
        }),
        attributes() {
        return [
            ...this.todos.map(todo => ({
            dates: todo.dates,
            highlight: {
                // color: todo.color,
                class: todo.isComplete,
                fillMode: 'solid',
                contentClass: 'assign_day',
                style: {
                    backgroundColor: todo.color,
                }
            },
            popover: {
                slot: 'todo-row',
            },
            customData: todo,
            })),
        ];
        },
    },
    watch:{
        workoutStep (val) {
            const vm = this;

            vm.toggleAll = 1;
        },
        "form.public_settings.workout_type": function(val) {
            if(val == 'recurring'){
                this.confirmation = true;
            }else{
                this.confirmation = false;
            }
        },
        "exercise.bodyweight": function(val) {
            if(val){
                this.exercise.units.value = 'BW';
            }
        },
        "exercise.units.value": function(val) {
            if(val == 'BW'){
                for (let i = 0; i < this.exercise.set_table.length; i++){
                    this.exercise.set_table[i].units.value = 'BW';
                }
            } else if(val == '1RM'){
                for (let i = 0; i < this.exercise.set_table.length; i++){
                    this.exercise.set_table[i].units.value = '1RM';
                }
            } else{
                for (let i = 0; i < this.exercise.set_table.length; i++){
                    this.exercise.set_table[i].units.value = this.exercise.units.value;
                }
            }
        },
        "exercise.rest": function(val){
            if(val){
                for (let i = 0; i < this.exercise.set_table.length; i++){
                    this.exercise.set_table[i].rest = this.exercise.rest;
                }
            }
        },
        "exercise.restTimeUnit.value": function(val){
            if(val){
                for (let i = 0; i < this.exercise.set_table.length; i++){
                    this.exercise.set_table[i].restUnit.value = this.exercise.restTimeUnit.value;
                }
            }
        },
        "exercise.failure": function(val){
            if(val){
                for (let i = 0; i < this.exercise.set_table.length; i++){
                    this.exercise.set_table[i].repUnit.value = 'Max Reps';
                }
            }else{
                for (let i = 0; i < this.exercise.set_table.length; i++){
                    this.exercise.set_table[i].repUnit.value = 'Reps';
                }
            }
        },
        "exercise.has_rest": function(val){
            if(!val){
                for (let i = 0; i < this.exercise.set_table.length; i++){
                    this.exercise.set_table[i].rest_min = '00';
                    this.exercise.set_table[i].rest_sec = '00';
                }
            }else{
                for (let i = 0; i < this.exercise.set_table.length; i++){
                    this.exercise.set_table[i].rest_min = this.exercise.rest_min;
                    this.exercise.set_table[i].rest_sec = this.exercise.rest_sec;
                }
            }
        }
    },
    methods:{
        toggleStep (step) {
            const vm = this;

            vm.workoutStep = step;
        },
        goTo (refName) {
            const vm = this;

            if (vm.isNew) {
                return false;
            }

            setTimeout(function () {
                if ( vm.progressStep != 2 && vm.progressStep != 3 ) {
                    setTimeout(function () {
                        let element = vm.$refs[refName];

                        setTimeout(function() {
                            let top = element.offsetTop;
                            document.querySelector('.preview_content .content_area').scrollTo(0, top);
                        }, 100);
                    }, 10);
                } else {
                    vm.$refs['preview-content'].goTo(refName);
                }
            }, 10);
        },

        setting (refName) {
            const vm = this;

            const section = ['cvrsetting', 'ftrsetting', 'subbtnsetting', 'formsetting', 'thankyousetting'];

            if (section.includes(refName)) {
                vm.progressStep = refName === 'formsetting' ? 2 : 3;

                setTimeout(function () {
                    let element = vm.$refs[refName];

                    setTimeout(function () {
                        if (element) {
                            let top = element.offsetTop;
                            document.querySelector('.global_setting .tabs_content').scrollTo(0, top);
                        }
                    }, 100);
                }, 100);
            }
        },
        toggleSection (ev) {
            const vm = this;
            let parentElm = ev.currentTarget.closest('.section_content');

            if (parentElm.classList.contains('show')) {
                parentElm.classList.remove('show');
                vm.toggleAll = 1;
            } else {
                parentElm.classList.add('show');
                vm.toggleAll = 0;
            }
        },

        openAllSection () {
            const vm = this;
            let allParentElm = document.querySelectorAll('.section_content');

            for(let i = 0; i < allParentElm.length; i++){
                allParentElm[i].classList.add('show');
            }

            vm.toggleAll = 0;
        },

        closeAllSection () {
            const vm = this;
            let allParentElm = document.querySelectorAll('.section_content');

            for (let i = 0; i < allParentElm.length; i++) {
                allParentElm[i].classList.remove('show');
            }

            vm.toggleAll = 1;
        },
        createNewBlock(type){
            const vm = this;

            if(type == 'Progression' || type == 'Rest Period' || type == 'Hypertrophy' ||  type == 'Strength' || type == 'Power'){
                vm.blockList.push({
                    block_name: type,
                    block_type: 1,
                    smart_block: 0,
                    block_round: 3,
                    amrap: 0,
                    block_rest_min: 0,
                    block_rest_sec: 30,
                    has_duration: 0,
                    block_duration_min: 4,
                    block_duration_sec: 0,
                    blockDurationUnit: {
                        mode: 'single',
                        value: 'Sec',
                        options: [
                            { value: 'Sec', label: 'Sec' },
                            { value: 'Min', label: 'Min' },
                        ]
                    },
                    work_interval: 1,
                    rest_interval: 2,
                    block_interval: 1,
                    items:[],
                });
            } else if(type == 'Smart Block'){
                vm.smart_block_setup = true;

            } else if(type == 'Warm-up'){
                vm.blockList.unshift({
                    block_name: type,
                    block_type: 2,
                    smart_block: 0,
                    block_round: 1,
                    amrap: 0,
                    block_rest_min: 0,
                    block_rest_sec: 20,
                    has_duration: 0,
                    block_duration_min: 4,
                    block_duration_sec: 0,
                    blockDurationUnit: {
                        mode: 'single',
                        value: 'Sec',
                        options: [
                            { value: 'Sec', label: 'Sec' },
                            { value: 'Min', label: 'Min' },
                        ]
                    },
                    work_interval: 1,
                    rest_interval: 2,
                    block_interval: 1,
                    items:[],
                });
            } else{
                vm.blockList.push({
                    block_name: type,
                    block_type: 2,
                    smart_block: 0,
                    block_round: 3,
                    amrap: 0,
                    block_rest_min: 0,
                    block_rest_sec: 30,
                    has_duration: 0,
                    block_duration_min: 4,
                    block_duration_sec: 0,
                    blockDurationUnit: {
                        mode: 'single',
                        value: 'Sec',
                        options: [
                            { value: 'Sec', label: 'Sec' },
                            { value: 'Min', label: 'Min' },
                        ]
                    },
                    work_interval: 1,
                    rest_interval: 2,
                    block_interval: 1,
                    items:[],
                });
            }
        },
        addToBlock(item){
            const vm = this;

            vm.blockList[vm.selectBlockPanel].items.push(item);
        },
        closeBlockTypes(){
            const vm = this;

            vm.newBlock = false;
        },
        deleteSectionBlock(el){
            const vm = this;

            vm.blockList.splice(el, 1);
        },
        deleteWorkout(panel, item){
            const vm = this;

            vm.blockList[panel].items.splice(item, 1);
        },
        copySectionBlock(el, i){
            const vm = this;

            vm.blockList.splice(i, 0, el);
        },
        closeBulkAction(){
            const vm = this;

            vm.bulk_action = false;
        },
        closeSortingType(){
            const vm = this;

            vm.type = false;
        },
        closeSortingBodyPart () {
            const vm = this;

            vm.body_part = false;
        },
        closeSortingMuscle () {
            const vm = this;

            vm.muscle_target = false;
        },
        closeSortingEquipment () {
            const vm = this;

            vm.equipment = false;
        },
        closeAddAttributes (){
            const vm = this;

            vm.exercise.addAttributes = false;
        },
        blockSetting(ev){
            let parentElm = ev.currentTarget.closest('.block_setting').querySelector('.dropdown_wpr');

            if (parentElm.classList.contains('active')) {
                parentElm.classList.remove('active');
            } else {
                parentElm.classList.add('active');
            }
        },
        selectFilterTag(tag, cat){
            const vm = this;

            const has_item = vm.selectedFilterTags.findIndex((att) => att.title === tag);

            if(has_item == -1){
                vm.selectedFilterTags.push({
                    type: cat,
                    title: tag,
                });
            } else{
                vm.selectedFilterTags.splice(has_item, 1);
            }
        },
        deleteFilterTag(t){
            const vm = this;

            vm.selectedFilterTags.splice(t, 1);
        },
        selectTag(tag, cat){
            const vm = this;

            const has_item = vm.exercise.selectedTags.findIndex((att) => att.title === tag);

            if(has_item == -1){
                vm.exercise.selectedTags.push({
                    type: cat,
                    title: tag,
                });
            } else{
                vm.exercise.selectedTags.splice(has_item, 1);
            }
        },
        deleteAtt(t){
            const vm = this;

            vm.exercise.selectedTags.splice(t, 1);
        },
        accFilter(ev){
            const vm = this;

            let el = ev.currentTarget.closest('.attribute_grp');
            if(el.classList.contains('active')){
                el.classList.remove('active');
                vm.allAttr = 1;
            }else{
                el.classList.add('active');
                vm.allAttr = 0;
            }
        },
        accFunc(ev){
            const vm = this;

            let el = ev.currentTarget.closest('.attribute_grp');
            if(el.classList.contains('active')){
                el.classList.remove('active');
                vm.allAttr = 1;
            }else{
                el.classList.add('active');
                vm.allAttr = 0;
            }
        },
        openAllGrp () {
            const vm = this;

            let allParentElm = document.querySelectorAll('.attribute_grp');

            for(let i = 0; i < allParentElm.length; i++){
                allParentElm[i].classList.add('active');
            }

            vm.allAttr = 0;
        },

        closeAllGrp () {
            const vm = this;
            let allParentElm = document.querySelectorAll('.attribute_grp');

            for (let i = 0; i < allParentElm.length; i++) {
                allParentElm[i].classList.remove('active');
            }

            vm.allAttr = 1;
        },
        blockEditFunc(el_link, el_name, el, el_rest_min, el_rest_sec){
            const vm = this;
            vm.editWorkout = true;
            vm.edit_thumb_ttl = el_name;
            vm.edit_thumb = el_link;

            if(el){
                vm.track_exercise = false,
                vm.exercise.sets = 1;
                vm.exercise.circuit = el;
                vm.exercise.time_min = 1;
                vm.exercise.time_sec = 0;
                vm.exercise.timeUnit.value = 'Min';
                vm.has_tempo = 0;

                if(el_rest_min || el_rest_sec){
                    vm.exercise.has_rest = 1;
                    vm.exercise.rest_sec = el_rest_sec;
                    vm.exercise.rest_min = el_rest_min;
                }else{
                    vm.exercise.has_rest = 0;
                }
            }else{
                vm.track_exercise = true,
                vm.exercise.sets = 3;
                vm.exercise.circuit = el;
                vm.has_tempo = 1;
            }
        },
        addAttrFunc(){
            const vm = this;
            let cat = '';

            if(vm.selectCategory == 'Exercise Type'){
                cat = 'exercise_type';
            } else if(vm.selectCategory == 'Body Part'){
                cat = 'body_part_filters';
            } else if(vm.selectCategory == 'Muscle Target'){
                cat = 'muscle_target_filters';
            } else if(vm.selectCategory == 'Equipment'){
                cat = 'equipment_filters';
            } else if(vm.selectCategory == 'Skeletal Action'){
                cat = 'skeletal_action';
            } else if(vm.selectCategory == 'Movement'){
                cat = 'movement_filters';
            } else if(vm.selectCategory == 'Body Position'){
                cat = 'body_position';
            } else if(vm.selectCategory == 'Contraction'){
                cat = 'contraction_filters';
            } else if(vm.selectCategory == 'Difficulty'){
                cat = 'difficulty_filters';
            }

            vm.cat.push({ title: `${vm.new_attr}`});
            vm.newAttribute = false;
        },
        shakeFunc(){
            const vm = this;

            vm.showContent = true;

            setTimeout(function(){
                vm.showContent = false;
            }, 2000);
        },
        addSmartBlock(smart_item){
            const vm = this;

            vm.blockList.push(smart_item);
        },

        addToSmart(item){
            const vm = this;

            vm.smartBlocks.push(item);
        }
    }
}
</script>

<style scoped>
    .workout-wizard-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .progress_item {
        border-radius: 10px;
        max-width: 400px;
        margin: 0 auto;
        border: 1px solid #e0e0e0;
    }

    .progress_item .item_img img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
    }

    .progress_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .progress_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .progress_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .progress_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .step_name {
        display: flex;
        align-items: center;
    }

    .step_name h3 {
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #121525;
        padding: 5px 0;
        transition: all 0.3s ease-in-out;
    }

    .step_wpr h3 {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #32373b;
        padding: 5px 0;
    }
    .sec_header{
        font-size: 17px;
        line-height: 25px;
        font-weight: 500;
        color: #32373b;
        padding: 5px 0;
    }

    .track_list {
        padding: 15px;
    }

    .track_list li {
        padding: 0 20px 20px 40px;
        position: relative;
    }

    .track_list li:not(:last-child):after {
        position: absolute;
        content: '';
        left: 14px;
        top: 0;
        bottom: 0;
        border-left: 2px dashed #b0d1ff;
    }

    .step_number {
        position: absolute;
        left: 4px;
        top: 0;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2f7eed;
        border: 3px solid #b0d1ff;
        color: #fff;
        z-index: 1;
        font-size: 9px;
        transition: all 0.3s ease-in-out;
    }

    .track_list li .step_name.active h3 {
        font-size: 15px;
        line-height: 20px;
    }

    .track_list li .step_name.active .step_number {
        font-size: 13px;
        left: 0;
        width: 30px;
        height: 30px;
    }

    .time_listing {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -7px;
    }

    .time_listing li {
        padding: 5px 7px;
        width: 20%;
    }

    .time_listing li .checkbox {
        display: block;
    }

    .selection_box {
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        border: 1px solid #d9d9d9;
        color: #121525;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .time_listing li .checkbox input[type=radio]:checked~.selection_box {
        border: 1px solid #b0d1ff;
        background: rgba(47, 126, 237, 0.05);
        color: #2f7eed;
    }

    .selection_box:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }

    .day_type {
        display: flex;
        flex-direction: column;
        margin: 0 -15px;
    }

    .day_type li {
        padding: 10px 15px;
        flex: 1 0 50%;
    }

    .day_type li .day_wpr {
        display: flex;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
    }

    .day_type li .day_wpr label {
        min-width: 50px;
        height: auto;
        background: #fff;
        border-right: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px 0 0 5px;
        cursor: pointer;
    }

    .day_type li .day_wpr label span {
        width: 20px;
        height: 20px;
        border: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 3px;
        background: rgba(255, 255, 255, 0.3);
    }

    .day_type li .day_wpr label span i {
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .day_type li .day_wpr label input[type=radio]:checked~span i {
        transform: scale(1);
    }

    .day_type li .day_wpr h4 {
        font-size: 13px;
        line-height: 30px;
        padding: 7px 15px;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .sqr_input {
        width: 30px;
        height: 22px;
        border: 1px solid #d9d9d9;
        margin: 0 5px;
        text-align: center;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-size: 12px;
        font-weight: 500;
    }

    .date_input {
        width: 140px;
        height: 25px;
        border: 1px solid #d9d9d9;
        margin: 0 5px;
        padding: 0 5px;
        text-align: left;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-size: 13px;
        font-weight: 500;
    }

    .multiselect.small_box {
        width: 80px;
        min-height: 25px;
        margin: 0 7px;
        font-size: 12px;
    }

    .sqr_input::-webkit-outer-spin-button,
    .sqr_input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .sqr_input[type=number] {
        -moz-appearance: textfield;
    }

    .time_selection {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    .time_selection .form_grp {
        padding: 10px;
        width: 50%;
        margin: 0;
        align-items: flex-end;
    }

    .day_sorting {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -5px;
    }

    .day_sorting li {
        padding: 5px;
        width: 25%;
        min-width: 90px;
    }

    .day_sorting li label {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 15px;
    }

    .circumference_list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 15px -10px;
    }

    .circumference_list li {
        padding: 10px;
        width: 50%;
    }

    .circumference_list li label {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        padding: 15px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .circumference_list li label:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .custom_list li {
        border: 1px solid #eaeaea;
        background: #f5f5f5;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 30px;
    }

    .custom_list li h5.category_bar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-weight: 500;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        padding: 0 15px;
        column-gap: 15px;
    }
    .category_bar .capsule_btn{
        border-right: 1px solid #d9d9d9;
    }
    .category_bar input{
        width: 100%;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        margin-right: 12px;
        padding: 15px 0;
    }
    .category_bar.active input, .category_bar input:focus{
        background: #f7faff;
        border-left: 1px solid #e9e9e9;
        border-right: 1px solid #e9e9e9;
        padding: 15px;
    }
    .category_bar .add_btn{
        font-size: 11px;
        line-height: 16px;
        margin: 0 0 0 15px;
        padding-right: 15px;
        border-right: 1px solid #d9d9d9;
    }
    .category_bar .add_btn i{
        font-size: 10px !important;
    }
    .category_bar .delete_btn{
        color: #eb1414;
        font-size: 11px;
        line-height: 16px;
        margin: 0 0 0 15px;
        cursor: pointer;
    }
    .category_bar .bar_text{
        display: flex;
        align-items: center;
        flex-grow: 1;
        position: relative;
    }
    .category_bar .bar_text:before{
        content: '';
        position: absolute;
        top:100%;
        left:0;
        right: 0;
        border-bottom: 1px solid #e9e9e9;
    }
    .category_bar .bar_actions{
        display: flex;
        align-items: center;
        height: 50px;
    }
    .custom_list li h5 span.save {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }
    .custom_list li h5 label {
        padding: 0 15px 0 0;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        cursor: auto;
        border-right: 1px solid #d9d9d9;
    }
    .custom_list li h5 label.has{
        color: #2f7eed;
    }

    .custom_list li h5 .edit_btn {
        font-size: 16px;
        color: #2F7FED;
        margin: 5px 0px;
        background: transparent;
        padding: 0px;
    }

    .custom_list li .section_wpr {
        border-top: 1px solid #eaeaea;
        margin: 10px -15px 0 -15px;
        padding: 10px 30px;
    }

    .sub_categories{
        padding: 30px 20px;
        border: 1px solid #eaeaea;
        background: #fafafa;
        border-radius: 6px;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .sub_categories h6{
        font-size: 13px;
        line-height: 18px;
        color: #999;
        font-weight: 400;
        text-align: center;
    }
    .category_bar .quest_title{
        flex: 1 1 auto;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        margin-right: 12px;
        padding: 15px 0;
    }
    .prog_edit{
        background: #fff;
        padding: 20px 30px 30px 30px;
        border: 1px solid #eaeaea;
        border-radius: 6px;
        margin-top: 10px;
        cursor: auto;
    }
    .prog_edit :deep(.tab_content){
        height: auto;
    }

    .prog_edit .field_wpr textarea {
        padding: 10px 15px;
        font-size: 13px;
        line-height: 18px;
        height: 100px;
        background: transparent;
        resize: none;
    }
    .grp_wpr {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        padding: 20px 0 5px 0;
    }

    .grp_wpr label .box_item{
        font-size: 13px;
        line-height: 15px;
        border-radius: 4px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
    }
    .grp_wpr label .box_item i{
        font-size: 15px;
    }
    .grp_wpr label.yes .box_item {
        border: 1px solid #b2eabf;
        background: #effff2;
        color: #23993e;
    }
    .grp_wpr label.no .box_item {
        border: 1px solid #ffb4b4;
        background: #ffecec;
        color: #eb1414;
    }
    .grp_wpr label input[type="radio"]:checked ~ .box_item {
        opacity: 1;
    }

    .question-options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 25px 0 5px;
        gap: 20px;
    }
    .question-options label {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }
    .question-options label p{
        font-size: 13px;
        line-height: 18px;
        padding-left: 12px;
    }
    .question-options .radio_box{
        width: 13px;
        height: 13px;
        border: 1px solid #5a5a5a;
        border-radius: 50%;
        position: relative;
        display: block;
        margin-top: 3px;
    }
    .question-options .radio_box .dot{
        position: absolute;
        background: #5a5a5a;
        border-radius: 50%;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .question-options label input[type="radio"]:checked ~ .radio_box .dot{
        transform: scale(1);
    }


    .modal.add_question .modal_header {
        padding: 0 45px;
    }

    .modal.add_question .modal_body {
        padding: 30px 45px 35px 45px;
        display: block;
    }

    .mcq_list li {
        border: 1px solid #d9d9d9;
        padding: 0 15px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .mcq_list li input {
        padding: 12px 15px 10px 0;
        font-size: 14px;
        line-height: 19px;
    }

    .mcq_list li button {
        border-left: 1px solid #d9d9d9;
        padding: 0 0 0 15px;
    }

    .col-2 {
        display: flex;
        margin: 0 -15px;
    }

    .col-2 .color_palette {
        flex: 1 0 50%;
        padding: 10px 15px;
    }

    .preview_content {
        width: 40%;
        position: relative;
        margin-right: -40%;
        transition: all 0.3s ease-in-out;
        background: #fff;
        z-index: 2;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 2px;
        font-size: 14px;
    }

    .tab_sec li {
        display: flex;
        align-items: center;
    }

    .tab_sec .tag {
        padding: 2px 5px;
        border-radius: 7px;
        font-size: 8px;
        line-height: 10px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 5px;
    }

    .cover_type {
        margin: 15px -35px 15px -35px;
        background: #fbfbfb;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        padding: 20px 35px;
    }

    .cover_type .type_list {
        justify-content: space-around;
        margin-top: 15px;
    }

    .days{
        display: flex;
        padding: 15px 0;
        gap: 7px;
    }
    .cell .days{
        border-top: 1px solid #E6E6E6;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0 20px;
    }
    .days li{
        padding: 4px 0;
        flex: 0 1 55px;
    }
    .days li label{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 10px 6px 12px 6px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
    }
    .days li label span{
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .days li label .tick_box{
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #5a5a5a;
        position: relative;
        margin-top: 10px;
    }
    .days li label .tick_box:after{
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: #5a5a5a;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .days li label input{
        margin-right: 7px;
        opacity: 0;
    }
    .days li label.active{
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid #b0d1ff;
    }
    .days li label.active .tick_box:after{
        transform: scale(1);
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .cell:after,
    .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .cell .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
    }

    .cell .sms_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }

    .cell .msg_preview:after,
    .cell .sms_preview::after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .sms_preview:after {
        right: 30px;
        left: auto;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview p,
    .cell .sms_preview p {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }

    .hourly_info {
        padding: 15px;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        background: #fff;
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #757575;
        text-align: center;
    }

    .form_grp :deep(.multiselect-single-label span) {
        font-size: 13px;
    }

    :deep(.cell .dashboard_footer) {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }

    :deep(.cell .dashboard_footer ul li) {
        padding: 2px;
    }

    :deep(.cell .dashboard_footer ul li a) {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }

    :deep(.cell .dashboard_footer h4) {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    :deep(.cell .dashboard_footer p) {
        font-size: 9px;
        line-height: 11px;
    }

    .info_bar {
        display: flex;
        flex-direction: column;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
        margin: 20px;
    }
    .time {
        min-width: 150px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 10px 25px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .time span {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        display: block;
    }
    .step_bar2 {
        position: relative;
        padding: 20px 30px 10px 30px;
    }
    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
        text-align: center;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
        text-align: left;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
        text-align: right;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }

    .reset-default {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 15px;
        color: #747474;
        font-weight: 400;
        cursor: pointer;
        position: relative;
    }

    .cover-header-with-text {
        display: flex;
    }

    .cover-header-with-text .small-cover-info {
        margin: 0;
        margin-left: 10px;
    }

    .cover-header-with-text div > small {
        font-size: 12px;
    }

    .flex-center{
        display: flex;
        align-items:center;
    }


    /* toggle_section */
    .toggle_section{
        width: 25px;
        height: 25px;
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }
    .toggle_section i{
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }

    .global_setting .tabs_content .section_content{
        position: relative;
    }
    .global_setting .tabs_content .section_content .section_header{
        margin: 10px 0;
    }
    .global_setting .tabs_content .section_content:after{
        content: '';
        position: absolute;
        left: 20px;
        top: 100%;
        right:20px;
        border-bottom: 1px solid #e9e9e9;
    }
    .global_setting .tabs_content .section_content.show:after, .global_setting .tabs_content .section_content:last-of-type:after, .global_setting .tabs_content .section_content.open_area:after{
        border: 0;
    }
    .global_setting .tabs_content .section_content .toggle_content{
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content.show .toggle_content{
        max-height: 1000vh;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content.show .toggle_section i{
        transform: rotate(-180deg);
    }

    /* --- */

    .section_content .section_header .status{
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 0 auto;
        flex-grow: 0;
    }
    .section_content .section_header .switch_option{
        flex: 1 1 auto;
    }
    .section_header h2 span{
        font-size: 13px;
        line-height: 17px;
        color: #f2a31d;
        display: block;
        padding-top: 7px;
    }
    .section_header h2 a{
        font-size: 13px;
        line-height: 17px;
        color: #121525;
        display: block;
        padding: 7px 7px 0 7px;
        position: relative;
    }
    .section_header h2 a .quick_info{
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        max-width: 200px;
        min-width: 100px;
        text-align: center;
        position: absolute;
        left: 0;
        top: auto;
        bottom: 100%;
        display: none;
        margin-bottom: 10px;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 7px 10px;
    }
    .section_header h2 a .quick_info:after {
        content: "";
        position: absolute;
        bottom: -6px;
        top: auto;
        left: 10px;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 0;
        background: #fff;
        transform: rotate(45deg);
        z-index: 2;
    }
    .section_header h2 a:hover .quick_info {
        display: block;
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .btn_list{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;
    }
    .btn_list li{
        font-size:13px;
        line-height: 16px;
        color: #121525;
        padding: 5px 10px;
        cursor: pointer;
    }

    @media(max-width: 1499px) {
        .time_selection .form_grp {
            flex: 0 0 100%;
            width: 100%;
        }

        .selection_box {
            font-size: 11px;
            line-height: 15px;
        }

        .time_listing li {
            padding: 3px;
        }

        .tab_sec li {
            font-size: 12px;
            line-height: 15px;
            padding: 8px 12px;
        }
    }

    :deep(.weekdays .multiselect-single-label-text) {
        text-transform: capitalize;
    }

    :deep(.question_list) {
        display: block;
        margin: 0;
    }

    :deep(.question_list li) {
        width: 100%;
    }

    :deep(.question_list li h5 label) {
        margin-right: auto;
        margin-left: 0;
        padding-left: 0;
    }

    .grp_wpr.grp_option {
        display: flex;
        flex-wrap: wrap;
    }

    .box_item {
        flex: 1 0 calc(50% - 10px);
    }

    .box_item label {
        border: 1px solid #CBCDD3;
        border-radius: 6px;
        padding: 5px 7px;
        display: flex;
        align-items: center;
    }

    .mrl-5 {
        margin: 0 -5px;
    }

    .p-10-15 {
        padding: 10px 15px !important;
    }
    :deep(.dp__input_wrap input){
        font-size: 12px;
        color: #5a5a5a;
    }
    .tab_slider{
        margin: 0 -15px;
    }
    :deep(.dp__main .dp__input){
        font-size: 11px;
        max-width: 120px;
        padding: 0 0 0 35px !important;
    }

    .addblock_section{
        position: relative;
        width: 100%;
        padding: 0 10px 20px 10px;
    }
    .addblock_section .add_types {
        max-width: 572px;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0,0,0,.1);
        text-align: center;
        z-index: 2;
    }
    .addblock_section .add_types:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        bottom: 100%;
        transform: scale(-1);
    }
    .addblock_section .add_types ul{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        padding: 30px 20px;
    }
    .addblock_section .add_types ul li{
        flex: 0 1 80px;
    }
    .addblock_section .add_types ul li .card_item{
        padding: 15px 5px;
    }
    .addblock_section .add_types .title{
        padding: 15px 30px;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        color: #121525;
    }
    .block_list .block_actions{
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .block_list .block_actions button{
        font-size: 15px;
        color: #121525;
        cursor: pointer;
    }
    .block_list .block_actions button.up_down{
        color: #999;
    }
    .block_list .block_actions .block_setting{
        position: relative;
        cursor: pointer;
    }
    .block_list .block_actions .block_setting i{
        font-size: 15px;
        color: #999;
    }
    .block_list .block_actions .block_setting .dropdown_wpr{
        min-width: 250px;
        max-height: 350px;
        overflow-y: scroll;
        right: 1px;
        left: auto;
        padding: 15px;
        margin-bottom: 10px;
        z-index: 4;
    }
    .block_list .block_actions .block_setting .dropdown_wpr::-webkit-scrollbar{
        display: none;
    }
    .block_list .block_actions .block_setting .dropdown_wpr:before{
        display: none;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .sec_label{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        padding: 3px 0;
        display: block;
    }
    .block_list .block_actions .block_setting .dropdown_wpr ul li{
        padding-top: 13px;
        border: 0;
        background: #f5f5f5;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        margin-bottom: 10px;
        border-radius: 5px;
    }
    .block_list .block_actions .block_setting .dropdown_wpr ul li .switch_option h5{
        font-weight: 400;
    }
    .block_list .block_actions .block_setting .dropdown_wpr .group_item .input_label{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr, .block_list .block_actions .block_setting .dropdown_wpr .form_grp .multiselect{
        background: #fff;
    }
    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr input, .block_list .block_actions .block_setting .dropdown_wpr .form_grp .multiselect{
        height: 30px;
        min-height: 30px;
        font-size: 11px;
    }
    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr.has_suffix{
        padding-right: 40px;
    }
    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr.has_suffix .suffix{
        width: 40px;
        font-size: 11px;
        line-height: 14px;
        background: #f5f5f5;
        border-radius: 0 5px 5px 0;
    }
    .block_list .block_actions .block_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown){
        min-width: 100%;
    }
    .block_list .block_actions .block_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown ul.multiselect-options li){
        padding: 5px 10px;
        font-size: 11px;
        line-height: 15px;
    }
    .block_list li .block_panel{
        border: 1px solid #eaeaea;
        background: #f5f5f5;
        border-radius: 6px;
        padding: 30px;
        margin-bottom: 40px;
        transition: all 0.3s ease-in-out;
    }
    .block_list li .block_panel.active{
        border: 1px solid #c8dfff;
        background: #fbfbfb;
    }
    .block_panel li.add_item button{
        width: 150px;
        border-radius: 6px;
        background: #fff;
        border: 1px solid #f5f5f5;
        padding: 20px 10px;
        min-height: 134px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        font-size: 13px;
        line-height: 18px;
        gap: 10px;
        color: #999;
        cursor: pointer;
    }

    .block_library{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0;
    }
    .block_library ul.list{
        display: flex;
        flex-wrap: wrap;
        margin: 20px -10px;
        width: 100%;
    }
    .block_library ul.list.shake{
        animation: headShake 0.3s linear 0s infinite alternate;
    }
    .block_library ul.list > li{
        padding: 20px 10px;
        width: 33.333%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        position: relative;
    }
    .video_wpr {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 5px;
        overflow: hidden;
    }
    .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }
    .block_library ul li h6{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight:500;
    }
    .block_library ul li .action_items{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0 10px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .block_library ul li:hover .action_items, .block_library ul li.selected .action_items, .block_library ul.target li .action_items{
        opacity: 1;
    }
    .block_library ul li .action_items label.checkbox span {
        width: 12px;
        flex: 0 0 12px;
        height: 12px;
        border-radius: 2px;
    }
    .block_library ul li .action_items label.checkbox span i{
        font-size: 8px;
    }
    .block_library ul li .action_items a{
        cursor: pointer;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
    }
    .block_library ul li .action_items a i{
        font-size: 9px;
        padding-right: 4px;
    }
    .block_library .library_header{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .block_library .library_header a{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
        cursor: pointer;
    }
    .block_library .filter_search {
        /* border: 1px solid #efefef; */
        height: 34px;
        border-radius: 20px;
        padding: 0 34px 0 0;
        position: relative;
        background: #fbfbfb;
        max-width: 400px;
        width: 100%;
    }
    .block_library .filter_search input{
        font-size: 11px;
        line-height: 34px;
        color: #666;
        border: 0;
        width: 100%;
        padding: 0 15px;
        background: transparent;
        box-sizing: border-box;
    }
    .block_library .filter_search .search_btn {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 0;
        font-size: 11px;
        color: #333;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }
    .block_library .filters{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin: 25px 0 10px 0;
        position: relative;
        z-index: 2;
    }
    .block_library .filters h5{
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 400;
    }
    .filters .filter_item, .attribute_list{
        position: relative;
        font-size: 11px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        cursor: pointer;
    }
    .filters .filter_item li, .other_filter_items li{
        font-size: 11px;
        line-height: 15px;
    }
    .other_filter_items{
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f1f1;
        border-radius: 50%;
        font-size: 10px;
        position: relative;
        cursor: pointer;
    }

    .attribute_bar{
        padding: 15px 0;
        background: #fff;
        border-left: 1px solid #e9e9e9;
        width: 230px;
        max-height: 100vh;
        overflow-y: overlay;
        position: relative;
        margin-right: -230px;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction:column;
    }
    .attribute_bar.show{
        margin-right: 0;
    }
    .attribute_bar::-webkit-scrollbar{
        width: 4px;
    }
    .attribute_bar::-webkit-scrollbar-thumb{
        border-radius: 2px;
        background: #cacaca;
    }
    .attribute_bar h3{
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        padding: 0 20px 5px 20px;
        display: flex;
        align-items: center;
    }
    .attribute_bar h3 button{
        font-size: 13px;
        color: #999;
        margin-left: auto;
        cursor: pointer;
    }
    .toggle_btn{
        display: flex;
        justify-content: flex-end;
        padding: 6px 20px 12px 20px;
    }
    .toggle_btn a{
        padding: 4px 10px;
        background: #f5f5f5;
        font-size: 10px;
        line-height: 12px;
        color: #121525;
        font-weight: 500;
        display: block;
        border-radius: 10px;
        cursor: pointer;
    }
    .attribute_grp{
        border-top: 1px solid #f5f5f5;
    }
    .attribute_grp h4{
        padding: 12px 15px;
        font-size: 13px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .attribute_grp h4 a{
        color: #5a5a5a;
        margin-right: 15px;
        font-size: 11px;
        display: flex;
        align-items: center;
    }
    .attribute_grp h4 a.pinned{
        color: #2f7eed;
    }
    .attribute_grp h4 > i{
        margin-left: auto;
        font-size: 11px;
        color: #999;
        transition: all 0.3s ease-in-out;
    }
    .attribute_grp ul{
        padding: 0 15px;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }
    .attribute_grp.active ul{
        max-height: 10000px;
    }
    .attribute_grp.active h4 > i{
        transform: rotate(-180deg);
    }
    .attribute_bar ul li{
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 10px 15px;
    }
    .attribute_bar ul li:last-child{
        margin-bottom: 15px;
    }
    .attribute_bar ul li label.checkbox, .dropdown_wpr ul li label.checkbox{
        justify-content: flex-start;
        gap: 10px;
        cursor: pointer;
    }
    .add_attribute{
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding: 10px 20px;
        background: #2f7eed;
        color: #fff;
        border-radius: 5px;
        margin: auto 20px 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        gap:7px;
        cursor: pointer;
    }
    .add_attribute i{
        font-size: 9px;
    }
    .filters .attribute_bar{
        position: fixed;
        right: -230px;
        top: 0;
        height: 100vh;
        overflow-y: scroll;
        transition: all 0.3s ease-in-out;
    }
    .filters .attribute_bar.show{
        right: 0;
    }
    .search_tags{
        background: #fbfbfb;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
        margin-top: 20px;
    }
    .search_tags li{
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: relative;
    }
    .search_tags .delete_btn{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        background: #eb1414;
        color: #fff;
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .search_tags li:hover .delete_btn{
        opacity: 1;
    }
    .dropdown_wpr ul li label.checkbox span i{
        margin: 0;        
    }
    .attribute_bar ul li label.checkbox span.active i, .dropdown_wpr ul li label.checkbox span.active i{
        transform: scale(1);
        opacity: 1;
    }
    .bulk_action{
        border-right: 1px solid #d9d9d9;
        padding-right: 20px;
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .bulk_action .delete_btn{
        color: #eb1414;
        cursor: pointer;
    }
    .filters .bulk_action .filter_item, .filters .bulk_action .filter_item i{
        color: #2f7eed;
    }
    .filter_item i{
        font-size: 9px;
        padding: 5px;
        vertical-align: top;
    }
    .filters .filter_item i, .attribute_list i {
        color: #7a7a7a;
        pointer-events: none;
    }
    .filters .filter_item .dropdown_wpr, .attribute_list .dropdown_wpr{
        min-width: 160px;
        overflow-y: hidden;
    }
    .filters .filter_item .dropdown_wpr ul, .attribute_list .dropdown_wpr ul{
        max-height: 400px;
        overflow-y: scroll;
    }
    .attribute_list .dropdown_wpr ul{
        max-height: 200px;
        overflow-y: scroll;
    }
    .filters .filter_item .dropdown_wpr ul::-webkit-scrollbar, .other_filter_items .dropdown_wpr::-webkit-scrollbar, .attribute_list .dropdown_wpr ul::-webkit-scrollbar, .attribute_list .dropdown_wpr ul::-webkit-scrollbar{
        display: none;
    }
    .filters .filter_item .dropdown_wpr ul li, .other_filter_items .dropdown_wpr ul li, .attribute_list .dropdown_wpr ul li{
        border: 0;
    }
    .filters .filter_item .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .dropdown_wpr ul li.dropdown-header{
        background: #e9e9e9;
    }

    .block_list li .block_panel ul{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }
    .block_list li .block_panel ul li{
        padding: 10px 15px;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        position: relative;
    }
    .block_list li .block_panel ul li .block_action{
        position: absolute;
        right: 15px;
        bottom: 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: all 0.3s ease-in-out;
        opacity: 0;
    }
    .block_list li.circuit .block_panel ul li .block_action{
        right: 30px;
    }
    .block_list li .block_panel ul li .block_action button{
        font-size: 11px;
        color: #eb1414;
        cursor: pointer;
    }
    .block_list li .block_panel ul li:hover .block_action{
        opacity: 1;
    }
    .block_list li .block_panel ul li h6{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight:500;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .block_list li .block_panel ul li h6 .v_tag{
        width: 14px;
        height: 14px;
        background: #999;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        line-height: 10px;
        font-weight: 500;
        color: #fff;
        margin-right: 5px;
        border-radius: 50%;
    }
    .block_list li .block_panel ul li.rest_list{
        width: auto;
    }
    .block_list li.circuit .block_panel ul li{
        padding-right: 30px;
    }
    .block_list li .block_panel ul li .circuit{
        width: 40px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        position: absolute;
        font-size: 15px;
        color: #2f7eed;
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
        z-index: 1;
        gap: 5px;
        visibility: hidden;
    }
    .block_list li .block_panel ul li .circuit a{
        font-size: 10px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        width: 40px;
        text-align:center;
    }
    .block_list li.circuit .block_panel ul li .circuit{
        visibility: visible;
    }
    /* .block_list li:not(.circuit) .block_panel ul li:nth-child(3):before{
        width: 22px;
        height: 22px;
        background: #2f7eed;
        border-radius: 11px;
        content: "SS";
        position: absolute;
        font-size: 10px;
        font-weight: 500;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.5px;
        top: 50%;
        left: -11px;
        transform: translateY(-50%);
        z-index: 1;
    } */

    .rest_list{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .rest_circle{
        width: 110px;
        height: 110px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #ffc4c4;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        cursor: pointer;
    }
    .rest_circle span{
        font-size: 15px;
        line-height: 20px; 
    }
    .edit_rest{
        width: 230px;
        position: absolute;
        top: 100%;
        right: 1px;
        left: auto;
        margin-top: 10px;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0,0,0,.1);
        text-align: center;
        z-index: 4;
    }
    /* .edit_rest:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        right: calc(100% - 4px);
        bottom: 50%;
        transform: rotate(90deg);
    } */
    .edit_rest .title{
        padding: 10px 20px;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
    }
    .edit_rest .setting_wpr{
        padding: 20px;
    }
    .edit_rest .input_label{
        font-size: 11px;
        line-height: 13px;
    }
    .edit_rest .field_wpr input{
        height: 32px;
        font-size: 11px;
    }
    .edit_rest :deep(.color-picker .field_wpr input){
        height: 30px;
        padding: 0 10px;
        font-size: 11px;
    }
    .edit_rest :deep(.color-picker .field_wpr.has_prefix){
        padding-left: 30px;
    }
    .edit_rest :deep(.color-picker .field_wpr.has_prefix .prefix-right){
        top: 3px;
        bottom: 3px;
        right: 3px;
    }
    .edit_rest :deep(.color-picker .field_wpr.has_prefix .prefix){
        width: 30px;
        height: 30px;
    }
    .edit_rest :deep(.color-picker .pickr), .edit_rest :deep(.color-picker .pickr .pcr-button){
        width: 20px;
        height: 20px;
        line-height: 0;
    }
    .edit_rest :deep(.color-picker .sub_header){
        font-size: 11px;
        line-height: 14px;
    }
    .edit_rest :deep(.color-picker .swatches li){
        padding: 4px;
    }
    .edit_rest :deep(.color-picker .swatches li span){
        width: 20px;
        height: 20px;
    }
    .edit_rest :deep(.color-picker .swatches li span i){
        font-size: 10px;
    }
    .edit_rest .action_wpr{
        padding: 12px 20px;
        border-top: 1px solid #f5f5f5;
        margin: 0;
    }
    .edit_rest .action_wpr .btn {
        min-width: auto;
        height: 30px;
        font-size: 10px;
        line-height: 28px;
        padding: 0 15px;
    }
    .edit_rest .action_wpr .btn.save_btn{
        color: #fff;
    }
    .workout_config .modal_container{
        padding: 0;
        max-width: 700px;
        width: 100%;
    }
    .workout_config .modal_header{
        padding: 20px 30px;
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
    }
    .workout_config .modal_footer{
        padding: 20px 30px;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        border-radius: 0 0 10px 10px;
    }
    .workout_config .modal_header h2 {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        text-align: left;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .workout_config .modal_header button{
        color: #999;
        font-size: 15px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-self: center;
    }
    .workout_config .modal_body{
        width: 100%;
        padding: 20px 30px;
        max-height: 600px;
    }
    .workout_config .edit_content{
        display: flex;
        gap: 30px;
        margin-bottom: 20px;
    }
    .workout_config .edit_content .thumb_section{
        flex: 0 1 600px;
        display: flex;
        gap: 20px;
        background: #fbfbfb;
        border-radius: 5px;
    }
    .workout_config .edit_content .thumb_section .thumb_title{
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        margin: 15px 0;
        display: flex;
    }
    .workout_config .edit_content .thumb_section .thumb_title .v_tag{
        width: 20px;
        height: 20px;
        background: #999;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        line-height: 14px;
        font-weight: 600;
        color: #fff;
        margin-left: 10px;
        border-radius: 50%;
    }
    .workout_config .edit_content .thumb_section .exercise_info{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        margin-top: 10px;
    }
    .workout_config .edit_content .video_section{
        flex: 0 1 280px;
    }
    .workout_config .edit_content .setting_wpr{
        flex: 1 0 180px;
    }
    .set_wpr{
        display: flex;
        margin: 0 -7px;
    }

    .set_wpr .single_item{
        flex: 0 1 15%;
        padding: 7px;
    }
    .set_wpr .public_name{
        flex: 0 1 294px;
        padding: 0 7px;
    }
    .set_wpr .public_descriptions{
        max-width: 544px;
        width: 100%;
        padding: 0 7px;
    }
    .set_wpr .item_wpr{
        flex: 0 1 25%;
        padding: 7px;
    }
    .set_wpr .item_wpr:last-of-type{
        margin-left: auto;
    }
    .column-2{
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
    }
    .column-2 .item{
        flex: 1 0 calc(50% - 7px);
    }
    .set_wpr .input_label{
        font-size: 14px;
        line-height: 19px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
    .workout_config .fld_label{
        font-size: 11px;
        line-height: 14px;
        margin-bottom: 5px;
        display: block;
    }
    .workout_config .field_wpr input, .workout_config .form_grp .multiselect{
        height: 40px;
        min-height: 40px;
        font-size: 13px;
    }
    .form_grp.set_wpr .multiselect {
        min-height: 40px;
    }
    .var_list{
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 10px 0;
    }
    .var_list .blank_space{
        min-width: 21%;
    }
    .radio_opt {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }
    .radio_opt p{
        font-size: 14px;
        line-height: 19px;
        padding-left: 8px;
    }
    .radio_box{
        width: 13px;
        height: 13px;
        border: 1px solid #5a5a5a;
        border-radius: 50%;
        position: relative;
        display: block;
        margin-top: 4px;
    }
    .radio_box .dot{
        position: absolute;
        background: #5a5a5a;
        border-radius: 50%;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .radio_opt input[type="radio"]:checked ~ .radio_box .dot{
        transform: scale(1);
    }
    .var_table{
        padding: 20px 0;
    }
    .var_table table{
        width:100%;
        background: #fbfbfb;
        border-radius: 6px;
        border-collapse: collapse;
    }
    .var_table table thead{
        border-bottom: 1px solid #efefef;
    }
    .var_table table tbody tr:not(:last-child){
        border-bottom: 1px solid #f3f3f3;
    }
    .var_table table th{
        padding: 12px 18px;
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 400;
    }
    .var_table table td{
        padding: 10px 18px 15px 18px;
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 300;
        vertical-align: bottom;
    }
    .var_table table td .row{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .var_table table td input{
        width: 50px;
        font-weight: 300;
        height: 30px;
        border: 1px solid #CBCDD3;
        border-radius: 5px;
        padding-left: 5px;
    }
    .other_content{
        width: 100%;
        padding: 20px 0 0 0;
    }
    .group_title{
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .group_title h4{
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        color: #121525;
        margin-bottom: 10px;
        margin-right: auto;
    }
    .group_title h4 span{
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        padding-left: 15px;
    }
    .att_tags{
        background: #fbfbfb;
        border-radius: 6px;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }
    .att_tags li{
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 7px 15px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: relative;
    }
    .att_tags .delete_btn{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        background: #eb1414;
        color: #fff;
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .att_tags li:hover .delete_btn{
        opacity: 1;
    }
    .att_tags li.exercise, .search_tags li.exercise{
        border-color: #88d3d9;
    }
    .att_tags li.position, .search_tags li.position{
        border-color: #b99191;
    }
    .att_tags li.contraction, .search_tags li.contraction{
        border-color: #968bca;
    }
    .att_tags li.muscle, .search_tags li.muscle{
        border-color: #76afff;
    }
    .att_tags li.movement, .search_tags li.movement{
        border-color: #cb72ff;
    }
    .att_tags li.equipment, .search_tags li.equipment{
        border-color: #56e094;
    }
    .att_tags li.skeletal, .search_tags li.skeletal{
        border-color: #fb76c8;
    }
    .att_tags li.difficulty, .search_tags li.difficulty{
        border-color: #f2a31d;
    }
    .other_content .field_wpr{
        background: #fff;
    }
    .other_content textarea{
        font-size: 13px;
        line-height: 18px;
        resize: vertical;
    }
    .video-overlay {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        top: 0;
        cursor: pointer;
    }
    .section_container {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
        padding: 30px 50px;
    }
    .section_title{
        font-size: 22px;
        line-height: 30px;
        color: #121525;
        font-weight: 400;
        text-align: center;
        padding: 0 0 20px 0;
    }
    .content_panel{
        border-radius: 8px;
        background: #fff;
        position: relative;
        padding: 30px;
    }
    .content_panel.circuit .set_wpr .single_item.sets, 
    .content_panel.circuit .set_wpr .item_wpr.rest, 
    .content_panel.circuit .form_grp :deep(ul.multiselect-options li#multiselect-option-Superset), 
    .content_panel.progression .form_grp :deep(ul.multiselect-options li[aria-label="Using Time"]), 
    .content_panel.no_superset .form_grp :deep(ul.multiselect-options li#multiselect-option-Superset), 
    .disable{
        pointer-events: none;
        opacity: 0.7;
    }
    .global_setting .section_header h2{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .global_setting .section_header h2 input{
        font-size: 16px;
        line-height: 20px;
        background: transparent;
        width: 200px;
        background: #f0f5ff;
        padding: 10px 15px;
        border-radius: 5px;
    }
    .global_setting .section_header h2 .save_btn{
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }
    .form_grp.small{
        margin: 0;
    }
    .form_grp.small .field_wpr{
        width: 80px;
    }
    .form_grp.small :deep(.multiselect-multiple-label), .form_grp.small :deep(.multiselect-placeholder), .form_grp.small :deep(.multiselect-single-label){
        padding: 0 20px 0 5px;
    }
    .form_grp.small :deep(.multiselect){
        min-height: 30px;
        height: 30px;
    }
    .form_grp.small :deep(.multiselect-single-label span){
        font-size: 11px;
    }
    .form_grp.small :deep(.multiselect-caret){
        margin-right: 5px;
    }

    .load_more{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        line-height: 18px;
        color: #999;
        gap: 10px;
    }

    .smart_blocklist .add_btn{
        font-size: 13px;
        line-height: 16px;
    }
    .smart_blocklist .add_btn i {
        margin-right: 5px;
        font-size: 9px !important;
    }
    .smart_blocklist > ul{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .smart_blocklist > ul .block_info{
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        padding: 7px 15px 20px 15px;
    }
    .smart_blocklist > ul .block_info > li{
        padding: 7px;
        width: 33.333%;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    .smart_blocklist > ul .block_info li h6{
        font-size: 9px;
        line-height: 11px;
        color: #121525;
        font-weight: 400;
        margin: 5px 0;
    }
    @keyframes smoothMove {
        0% {
            bottom: 30%;
            opacity: 0;
        }

        10% {
            bottom: 30%;
            opacity: 0.5;
        }

        100% {
            bottom: 100%;
            opacity: 1;
        }
    }
    @media(max-width: 1199px){
        .info_bar{
            margin: 20px 10px;
        }
        .cover_type{
            margin: 15px -25px;
        }
    }
    @media(max-width: 991px){
        .prog_edit{
            padding: 20px;
        }
    }
    @media(max-width: 767px){
        .category_bar input, .category_bar .quest_title{
            font-size: 13px;
        }
    }
    @media(max-width: 499px){
        .day_type li .day_wpr h4{
            font-size: 11px;
        }
        .sqr_input{
            width: 20px;
            height: 20px;
            font-size: 10px;
        }
        :deep(.dp__main .dp__input){
            font-size: 10px;
            padding: 0 0 0 25px !important;
        }
        :deep(.dp__main .dp__input_icons){
            width: 12px;
        }
        .day_type li .day_wpr label{
            width: 40px;
            min-width: auto;
            flex-shrink: 0;
        }
        .time_listing li{
            width: 25%;
        }
        .circumference_list li{
            width: 100%;
        }
        .custom_list li{
            padding: 15px;
        }
        .custom_list li .section_wpr{
            padding: 10px 20px;
        }
        .category_bar .capsule_btn{
            padding: 0 10px 0 0;
        }
        .category_bar .add_btn{
            margin: 0 0 0 10px;
            padding-right: 10px;
        }
        .sub_categories{
            padding: 20px 15px;
        }
        .prog_edit{
            padding: 15px;
        }
        .cover_type{
            margin: 15px -15px;
        }
    }
</style>
